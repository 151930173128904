<script>
	// ASSETS
	import Logo from '../../../public/assets/img/LOGOsansINFO.png';

	// COMPONENTS
	import LoadingDots from '../../components/Loading/LoadingDots.svelte';

	import { post } from '../../API/Backend/api';
	import { navigate } from 'svelte-routing';
	import { afterUpdate } from 'svelte';
	import { addToast, isConnected } from '../../store.js';
	import * as jose from 'jose';

	// VARIABLES
	let email = '';
	let password = '';

	afterUpdate(async () => {
		if (localStorage.getItem('email') && email == '') {
			email = localStorage.getItem('email');
		}
	});

	async function getToken() {
		await post('/auth/login', { email, password }).then(async (login) => {
			if (login.token) {
				addToast('Connection avec succès !', 'bg-green-500');

				const secret = new TextEncoder().encode(process.env.JWT_SECRET);
				// Decode the token
				let decodedToken =  await jose.jwtVerify(login.token,secret);
					
				// Save the token in the sessionStorage
				sessionStorage.setItem('token', login.token);
				sessionStorage.setItem('rights', JSON.stringify(decodedToken.payload.rights));
				localStorage.setItem('email', email);
				isConnected.set(true);
				navigate('/accueil');
				return;
			}

			addToast('Erreur de connexion. Veuillez vérifier vos identifiants.', 'bg-red-500');
			password = '';
		});
	}

	function handleKeyPress(e) {
		if (e.key === 'Enter') {
			e.preventDefault(); // Prevent form submission
			connect();
		}
	}

	let loginPromise;
	async function connect() {
		loginPromise = getToken();
	}

	// Function to toggle password visibility
	let show_password = false;
	$: type = show_password ? 'text' : 'password';
</script>

<div class="container mx-auto px-4 h-full">
	<div class="flex min-h-full flex-col justify-center px-6 py-12 lg:px-8 bg-primary-light">
		<div class="sm:mx-auto sm:w-full sm:max-w-sm">
			<img class="mx-auto h-28 w-auto" src={Logo} alt="Assortir logo" />
			<h2 class="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
				Connectez-vous à Lisi-Connect
			</h2>
		</div>

		<div class="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
			<form class="space-y-6 w-96" action="#" method="POST">
				<div>
					<label for="username" class="block text-sm font-medium leading-6 text-gray-900"
						>E-mail</label
					>
					<div class="mt-2">
						<input
							bind:value={email}
							id="username"
							name="username"
							type="email"
							autocomplete="given-name"
							required
							class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primaryLight sm:text-sm sm:leading-6"
						/>
					</div>
				</div>

				<div>
					<div class="flex items-center justify-between">
						<label for="password" class="block text-sm font-medium leading-6 text-gray-900"
							>Mot de passe</label
						>
					</div>
					<div class="mt-2 relative">
						<input
							id="password"
							name="password"
							{type}
							autocomplete="current-password"
							required
							class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primaryLight sm:text-sm sm:leading-6"
							on:keypress={handleKeyPress}
							on:input={(e) => (password = e.target.value)}
						/>

						<button
							type="button"
							on:click={() => (show_password = !show_password)}
							class="absolute top-0 end-0 p-3.5 rounded-e-md"
						>
							<svg
								class="flex-shrink-0 size-3.5 text-gray-400 dark:text-neutral-600"
								width="24"
								height="24"
								viewBox="0 0 24 24"
								fill="none"
								stroke="currentColor"
								stroke-width="2"
								stroke-linecap="round"
								stroke-linejoin="round"
							>
								<path d="M9.88 9.88a3 3 0 1 0 4.24 4.24"></path>
								<path
									d="M10.73 5.08A10.43 10.43 0 0 1 12 5c7 0 10 7 10 7a13.16 13.16 0 0 1-1.67 2.68"
								></path>
								<path d="M6.61 6.61A13.526 13.526 0 0 0 2 12s3 7 10 7a9.74 9.74 0 0 0 5.39-1.61"
								></path>
								{#if show_password}
									<line x1="2" x2="22" y1="2" y2="22"></line>
								{/if}
								<path d="M2 12s3-7 10-7 10 7 10 7-3 7-10 7-10-7-10-7Z"></path>
								<circle class="hidden hs-password-active:block" cx="12" cy="12" r="3"></circle>
							</svg>
						</button>
					</div>
				</div>

				<div>
					{#await loginPromise}
						<LoadingDots />
					{:then next}
						<button
							on:click={connect}
							type="submit"
							class="flex w-full justify-center rounded-md bg-primary px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-primary-dark focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary"
							>Connectez-vous</button
						>
					{/await}
				</div>
			</form>
		</div>
	</div>
</div>

<style>
	input:-webkit-autofill,
	input:-webkit-autofill:hover,
	input:-webkit-autofill:focus,
	input:-webkit-autofill:active {
		-webkit-box-shadow: 0 0 0px 1000px white inset !important;
		box-shadow: 0 0 0px 1000px white inset !important;
		-webkit-text-fill-color: #1f2937 !important; /* text-gray-900 */
		border-radius: 0.375rem; /* rounded-md */
		border: none;
		padding: 0.375rem 0.75rem; /* py-1.5 */
		color: #1f2937; /* text-gray-900 */
		background-color: white !important; /* background-color: white */
		caret-color: black; /* Assurez-vous que le curseur de texte est noir */
		border: 1px solid #d1d5db; /* border-0 */
	}

	input:-webkit-autofill:focus {
		box-shadow:
			0 0 0px 1000px white inset,
			0 0 0 2px #fca5a5 !important; /* focus:ring-primaryLight */
		outline: none;
	}

	input:focus {
		outline: none;
		ring-color: #fca5a5; /* focus:ring-primaryLight */
	}
</style>
