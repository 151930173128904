import { fetchData } from './utils.js';

/**
 *
 * @returns {Promise<{data: any}>}
 * @description Get all pays
 *
 */

export async function getPays() {
	return await fetchData('/Pays/LP');
}