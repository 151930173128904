import { fetchData } from './utils.js';

/**
 * @description Get the clients of a collaborateur
 * @param {JWTPayload} user
 * @param {*} queryParams
 * @returns {Promise<{data: any, pagination: any}>}
 */
export async function getCollaborateurClients(user, queryParams) {
	return await fetchData(`/service/collaborateur/LC/${user.sageLink}`, queryParams);
}

/**
 * @description Get the articles of a collaborateur
 * @param {JWTPayload} user
 * @param {*} queryParams
 * @returns {Promise<{data: any, pagination: any}>}
 */
export async function getCollaborateurArticle(user, queryParams) {
	return await fetchData(`/service/collaborateur/LA/${user.sageLink}`, queryParams);
}

/**
 * @param {string} codePostal
 * @returns {Promise<*>}
 */
export async function getCommunesFromCodePostal(codePostal) {
	return await fetchData(`/service/communes/${codePostal}`);
}