<script>
	import { ChevronDown, ChevronUp, Icon } from 'svelte-hero-icons';
	import { onMount } from 'svelte';

	export let title = '';
	export let isOpen = true;
	export let isCollapsible = true;

	let content;

	onMount(() => {
		if (!isOpen) {
			content.classList.add('hidden')
		}
	})
	function openCollapsible() {
		if (isCollapsible) {
			if (isOpen) {
				// Fermeture : fixe la hauteur pour démarrer l'animation
				content.style.maxHeight = `${content.scrollHeight}px`;
				requestAnimationFrame(() => {
					content.style.maxHeight = '0';
				});
			} else {
				// Ouverture : fixe temporairement la hauteur pour déclencher l'animation
				content.style.maxHeight = '0';
				requestAnimationFrame(() => {
					content.style.maxHeight = `${content.scrollHeight}px`;
				});
			}
			isOpen = !isOpen;
		}
	}
</script>

<button type="button" on:click={openCollapsible} class="w-full mb-2 mt-4 cursor-default">
	<span class="flex items-baseline">
		<span class="whitespace-nowrap border-none text-xl font-semibold leading-none tracking-tight">
			{ title }
		</span>
		<span class="flex-grow ml-4 relative border-t border-gray-300"></span>
		{#if isCollapsible}
			<Icon src={isOpen ? ChevronUp : ChevronDown} class="w-5 h-5 ml-auto" />
		{/if}
	</span>
</button>


<div
	bind:this={content}
	on:transitionend={() => isOpen ? '' : content.classList.add('hidden')}
	class="ml-2 transition-all duration-500 ease-in-out {isOpen ? 'max-h-none opacity-100' : 'max-h-0 opacity-0'}">
	<slot></slot>
</div>
