<script>
    import { Router, Route } from 'svelte-routing';

    // pages for this layout
    import IndexUrssaf from '../views/urssaf/IndexUrssaf.svelte';
    import NotFound from '../views/404.svelte';
    import ListeClient from '../views/urssaf/ListeClient.svelte';
    import EditClient from '../views/urssaf/EditClient.svelte';
    import ListeDemandePaiement from '../views/urssaf/ListeDemandePaiement.svelte';
</script>

<Router url="urssaf">
    <Route path="/" component={IndexUrssaf} />
    <Route path="/liste-clients" component={ListeClient} />
    <Route path="/clients/modifier" component={EditClient} />
    <Route path="/liste-demandes-paiement" component={ListeDemandePaiement} />
    <Route path="*" component={NotFound} />
</Router>