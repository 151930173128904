import CompteAnalytiqueModel from './CompteAnalytiqueModel';
import CollaborateurDocument from './CollaborateurDocument';
import InfoLibreModel from './InfoLibreModel';
import ArticleReferenceModel from './ArticleReferenceModel';
import AcompteDocumentVenteUpdateModel from './AcompteDocumentVenteUpdateModel';
import LivraisonAdresseModel from './LivraisonAdresseModel';

/**
 * @class Model
 * @description Model generated from schema
 * @property {string} CtNum - 
 * @property {string} Status - 
 * @property {string} Reference - 
 * @property {CompteAnalytiqueModel} CodeAffaire - 
 * @property {string} Entete1 - 
 * @property {string} Entete2 - 
 * @property {string} Entete3 - 
 * @property {string} Entete4 - 
 * @property {string} Contact - 
 * @property {CollaborateurDocument} Collaborateur - 
 * @property {string} Date - 
 * @property {string} TierPayeur - 
 * @property {string} Expediteur - 
 * @property {string} CentralAchat - 
 * @property {string} DO_DateLivr - 
 * @property {number} DO_ValFrais - 
 * @property {number} DE_No - 
 * @property {Array<InfoLibreModel>} InfoLibres - 
 * @property {Array<ArticleReferenceModel>} ListeRefArticle - 
 * @property {Array<AcompteDocumentVenteUpdateModel>} Acomptes - 
 * @property {LivraisonAdresseModel} LivraisonAdresse - 
 */
class DocumentVenteCreationModel {
  /**
   * @constructor
   */
  constructor() {
    /** @type {string} */
    this.CtNum = null;
    /** @type {string} */
    this.Status = null;
    /** @type {string} */
    this.Reference = null;
    /** @type {CompteAnalytiqueModel} */
    this.CodeAffaire = null;
    /** @type {string} */
    this.Entete1 = null;
    /** @type {string} */
    this.Entete2 = null;
    /** @type {string} */
    this.Entete3 = null;
    /** @type {string} */
    this.Entete4 = null;
    /** @type {string} */
    this.Contact = null;
    /** @type {CollaborateurDocument} */
    this.Collaborateur = null;
    /** @type {string} */
    this.Date = null;
    /** @type {string} */
    this.TierPayeur = null;
    /** @type {string} */
    this.Expediteur = null;
    /** @type {string} */
    this.CentralAchat = null;
    /** @type {string} */
    this.DO_DateLivr = null;
    /** @type {number} */
    this.DO_ValFrais = null;
    /** @type {number} */
    this.DE_No = null;
    /** @type {Array<InfoLibreModel>} */
    this.InfoLibres = [];
    /** @type {Array<ArticleReferenceModel>} */
    this.ListeRefArticle = [];
    /** @type {Array<AcompteDocumentVenteUpdateModel>} */
    this.Acomptes = [];
    /** @type {LivraisonAdresseModel} */
    this.LivraisonAdresse = null;
  }
}

export default DocumentVenteCreationModel;
