<script>
	// Components
	import DataTable from '../../components/DataTable.svelte';
	import Drawer from '../../components/Drawer.svelte';
	import ButtonAction from '../../components/Actions/ButtonAction.svelte';
	import Modal from '../../components/Modal.svelte';
	import { Icon, ExclamationTriangle, XMark, ExclamationCircle, Check } from 'svelte-hero-icons';
	import Select from '../../components/Select/Select.svelte';
	
	// Client
	import { getClients } from '../../API/API_SAGE/ClientQueries';
	import { getUrssafStatus, inscrireClient } from '../../API/API_SAGE/UrssafQueries';

	// Store
	import { addToast, openDrawer } from '../../store';

	// Svelte
	import { onMount } from 'svelte';
	import { connectedUserHasAccess } from '../../API/Backend/api.js';
	import { List } from '../../API/Backend/rights/permissions.js';
	import { openUrlInCurrentTab } from '../../utils.js';

	const unselectedColumns = [
		'Adresse.CT_Adresse', 'Adresse.CT_CodePostal', 'Adresse.CT_Pays',
	];

	const statusOptions = [
		{ value: '0', label: 'Non inscrit' },
		{ value: '1', label: 'En cours d\'inscription' },
		{ value: '2', label: 'Inscrit' }
	];

	let columns = [
		{ key: 'Urssaf', title: '', type: 'indicator', action: actionAPIIndicator, canSort: false, canSearch: false },
		{ key: 'CT_Intitule', title: 'Intitulé' },
		{ key: 'Adresse.CT_Ville', title: 'Ville', canSort: false, canSearch: false },
		{ key: 'Telecom.CT_Telephone', title: 'Tél', canSort: false, canSearch: false },
		{ key: 'Telecom.CT_EMail', title: 'Email', type: 'email', canSort: false, canSearch: false }
	];

	let actions = null;
	let bulkActions = null;
	let showErrorModal = false;
	let showCheckModal = false;
	let errorMessages = [];
	let messages = [];
	let selectedStatus = sessionStorage.getItem('selectedUrssafStatus') ?? '0';

	onMount(() => {
		connectedUserHasAccess('client', List);
	});

	// Fonction pour récupérer les clients avec le filtre URSSAF
	function actionAPIIndicator(row) {
		let apiUrssaf = row.InfoLibres.find(res => res.Intitule === "LS_API");
		if (apiUrssaf) {
			switch(apiUrssaf.Valeur) {
				case "2": return 'bg-green-500';  // En attente de validation
				case "1": return 'bg-yellow-300';  // En cours d'inscription
				case "0": return 'bg-transparent'; // Non inscrit
				default: return 'bg-transparent';
			}
		}
		return 'bg-transparent';
	}

	function getUrssafClients(strictSearchFields, strictSearchValues, excludeSearchFields, excludeSearchValues, searchFields, searchValues, OrderDirection, pageNumber = 1, pageSize = 10, OrderBy) {
		switch (selectedStatus) {
			case '0':
				bulkActions = null;
				actions = [{
					component: ButtonAction,
					props: {
						content: 'Inscrire à l\'URSSAF',
						action: 'inscription',
						condition: (row) => {
							const apiStatus = row.InfoLibres.find(info => info.Intitule === "LS_API")?.Valeur;
							return !apiStatus || apiStatus === "0";
						}
					}
				},
					{
						component: ButtonAction,
						props: {
							content: 'Modifier',
							action: 'edition',
							condition: (row) => {
								const apiStatus = row.InfoLibres.find(info => info.Intitule === "LS_API")?.Valeur;
								return apiStatus === "0";
							}
						}
					}];
				break;
			case '1':
				bulkActions = [{ component: ButtonAction, props: { content: "Vérifier l'inscription", action: 'check', classes: 'py-2 text-sm mt-2' }}];
				actions = null;
				break;
			case '2':
				bulkActions = null;
				actions = null;
				break;
		}
		return getClients('LS_API', selectedStatus, excludeSearchFields, excludeSearchValues, searchFields, searchValues, OrderDirection, pageNumber, pageSize, OrderBy);
	}

	async function handleRowClick(event) {
		const rowData = event.detail;
		openDrawer({rowData});
	}

	async function handleInscription(event) {
		const client = event.detail;
		try {
			const response = await inscrireClient(client.CT_Num, {
				siret: client.CT_Identifiant,
				raisonSociale: client.CT_Intitule,
			});

			if (response.data.idClient) {
				addToast('Client inscrit avec succès à l\'URSSAF', 'bg-green-500');
			} else if (response.data && Array.isArray(response.data)) {
				errorMessages = response.data.map(error => ({
					field: error.message.split('champ ')[1]?.split(' :')[0] || 'Champ inconnu',
					message: error.message.split(': ')[1] || error.message
				}));
				showErrorModal = true;
			} else {
				addToast(response.detail || 'Erreur lors de l\'inscription', 'bg-red-500');
			}
		} catch (error) {
			addToast('Erreur lors de l\'inscription à l\'URSSAF', 'bg-red-500');
		}
	}

	function handleEdition(event) {
		const client = event.detail;
		let params = new URLSearchParams({
			payload: JSON.stringify(client)
		});
		openUrlInCurrentTab(`/urssaf/clients/modifier?${params.toString()}`);
	}

	async function handleCheck(event) {
		let clients = event.detail.map(client => client.CT_Num);
		if (clients.length === 0) {
			addToast('Veuillez sélectionner au moins un client', 'bg-yellow-500');
			return;
		}
		let response = await getUrssafStatus(clients).then(res => res.data);

		messages = response.map(message => {
			if (Array.isArray(message)) {
				return message.map(error => ({
					title: error.message,
					message: error.description,
					isError: true
				}));
			}

			return {
				title: `${message.nomNaissance} ${message.prenoms}`,
				message: `${message.statut.description}`,
			};
		}).flat();

		showCheckModal = true;
	}
</script>

<div class="grid grid-cols-1 gap-4 m-2">
	<div class="space-y-2">
		<Select
			id="statusType"
			label="Statut URSSAF"
			options={statusOptions}
			bind:value={selectedStatus}
			on:change={() => {
				sessionStorage.setItem('selectedUrssafStatus', selectedStatus);
				window.dispatchEvent(new CustomEvent('refresh'))
			}}
			width="w-1/3"
		/>
	</div>
</div>

{#key selectedStatus}
	<DataTable
		getQuery={getUrssafClients}
		{columns}
		{actions}
		unselectedColumnsKeys={unselectedColumns}
		rowsPerPage={10}
		bulkActions={bulkActions}
		on:rowClick={handleRowClick}
		on:inscription={handleInscription}
		on:edition={handleEdition}
		on:check={handleCheck}
	/>
{/key}

<Drawer title="Détails du client">
	<div slot="drawerContent" let:data>
		<p>Numéro: {data.rowData.CT_Num}</p>
		<p>Intitulé: {data.rowData.CT_Intitule}</p>
	</div>
</Drawer>

<Modal show={showErrorModal} title="Erreurs de validation URSSAF" on:close={() => showErrorModal = false}>
	<div class="p-6 space-y-6">
		<div class="flex items-start">
			<div class="ml-3 w-full">
				<div class="flex items-center mb-4">
						<Icon src={ExclamationCircle} class="h-6 w-6 text-red-500" />
						<h3 class="text-lg font-medium text-gray-900">
								Impossible de procéder à l'inscription
						</h3>
				</div>
				<div class="bg-red-50 p-4 rounded-lg">
					<div class="mb-3 text-sm text-gray-700">
						Les champs suivants nécessitent une correction :
					</div>
					<ul class="space-y-3">
						{#each errorMessages as error}
							<li class="flex items-start">
								<span class="flex-shrink-0 h-5 w-5 text-red-400">
									<Icon src={XMark} />
								</span>
								<div class="ml-2">
									<span class="text-sm font-medium text-gray-900">{error.field}</span>
									<p class="text-sm text-gray-600">{error.message}</p>
								</div>
							</li>
						{/each}
					</ul>
				</div>
				<div class="mt-6 bg-yellow-50 p-4 rounded-lg">
					<div class="flex">
						<div class="flex-shrink-0">
							<Icon src={ExclamationTriangle} class="h-5 w-5 text-yellow-400" />
						</div>
						<div class="ml-3">
							<p class="text-sm text-yellow-700">
								Veuillez mettre à jour ces informations dans la fiche client avant de réessayer l'inscription.
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="bg-gray-50 px-6 py-4 flex justify-end rounded-b-lg">
		<button
			type="button"
			class="bg-gray-100 text-gray-700 hover:bg-gray-200 px-4 py-2 rounded-md text-sm font-medium transition-colors"
			on:click={() => showErrorModal = false}
		>
			Fermer
		</button>
	</div>
</Modal>

<Modal show={showCheckModal} title="Résultats de la vérification" on:close={() => showCheckModal = false}>
	<div class="p-6 space-y-6">
		<div class="flex items-start">
			<div class="ml-3 w-full">
				<ul class="space-y-3">
					{#each messages as message}
						<li>
							<div class="bg-{message.isError ? 'red' : 'green' }-50 p-4 rounded-lg flex items-start">
								<span class="flex-shrink-0 h-5 w-5 text-{message.isError ? 'red' : 'green' }-400">
										<Icon src={message.isError ? XMark : Check} />
								</span>
								<div class="ml-2">
									<span class="text-sm font-medium text-gray-900">{message.title}</span>
									<p class="text-sm text-gray-600">{message.message}</p>
									{#if message.description}
										<p class="text-xs text-{message.isError ? 'red' : 'green' }-500">{message.description}</p>
									{/if}
								</div>
							</div>
						</li>
					{/each}
				</ul>
			</div>
		</div>
	</div>
	<div class="bg-gray-50 px-6 py-4 flex justify-end rounded-b-lg">
		<button
			type="button"
			class="bg-gray-100 text-gray-700 hover:bg-gray-200 px-4 py-2 rounded-md text-sm font-medium transition-colors"
			on:click={() => showCheckModal = false}
		>
			Fermer
		</button>
	</div>
</Modal>
