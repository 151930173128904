import { fetchData } from './utils.js';

/**
 * @description Get the status of the URSSAF
 * @param {[*]} ctNums
 * @returns {Promise<{data: any, pagination: any}>}
 */
export async function getUrssafStatus(ctNums) {
	return await fetchData('/urssaf/status', null, 'POST', ctNums);
}

/**
 * @description Inscrire un client à l'URSSAF
 * @param {string} ctNum
 * @param {any} data
 * @returns {Promise<{data: any, pagination: any}>}
 */
export async function inscrireClient(ctNum, data) {
	return await fetchData(`/urssaf/particulier/${ctNum}`, data, 'POST');
}

/**
 * @description Transmettre une demande de paiement à l'URSSAF
 * @param {any} data
 * @returns {Promise<{data: any, pagination: any}>}
 */
export async function transmettreDemandesPaiement(data) {
	return await fetchData('/urssaf/demandePaiement', null, 'POST', data);
}

/**
 * @description Rechercher une demande de paiement à l'URSSAF
 * @param {any} data
 * @returns {Promise<{data: any, pagination: any}>}
 */
export async function rechercherDemandesPaiement(data) {
	return await fetchData('/urssaf/demandePaiement/rechercher', null, 'POST', data);
}
