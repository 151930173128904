

/**
 * @class Model
 * @description Représentation d'une devise dans Sage
 * @property {string} D_Intitule - 
 * @property {string} D_Format - 
 * @property {number} D_Cours - 
 * @property {number} D_CoursP - 
 * @property {string} D_Monnaie - 
 * @property {string} D_SousMonnaie - 
 * @property {string} D_CodeISO - 
 * @property {string} D_Sigle - 
 * @property {number} D_Mode - 
 * @property {number} N_DeviseCot - 
 * @property {number} D_CoursClot - 
 * @property {string} D_AncDate - 
 * @property {number} D_AncCours - 
 * @property {number} D_AncMode - 
 * @property {number} N_DeviseAncCot - 
 * @property {number} D_CodeRemise - 
 * @property {number} D_Euro - 
 * @property {string} D_CodeISONum - 
 * @property {string} D_UpdateDate - 
 * @property {string} D_UpdateTime - 
 * @property {number} cbIndice - 
 * @property {number} cbMarq - 
 */
class DeviseResponse {
  /**
   * @constructor
   */
  constructor() {
    /** @type {string} */
    this.D_Intitule = null;
    /** @type {string} */
    this.D_Format = null;
    /** @type {number} */
    this.D_Cours = null;
    /** @type {number} */
    this.D_CoursP = null;
    /** @type {string} */
    this.D_Monnaie = null;
    /** @type {string} */
    this.D_SousMonnaie = null;
    /** @type {string} */
    this.D_CodeISO = null;
    /** @type {string} */
    this.D_Sigle = null;
    /** @type {number} */
    this.D_Mode = null;
    /** @type {number} */
    this.N_DeviseCot = null;
    /** @type {number} */
    this.D_CoursClot = null;
    /** @type {string} */
    this.D_AncDate = null;
    /** @type {number} */
    this.D_AncCours = null;
    /** @type {number} */
    this.D_AncMode = null;
    /** @type {number} */
    this.N_DeviseAncCot = null;
    /** @type {number} */
    this.D_CodeRemise = null;
    /** @type {number} */
    this.D_Euro = null;
    /** @type {string} */
    this.D_CodeISONum = null;
    /** @type {string} */
    this.D_UpdateDate = null;
    /** @type {string} */
    this.D_UpdateTime = null;
    /** @type {number} */
    this.cbIndice = null;
    /** @type {number} */
    this.cbMarq = null;
  }
}

export default DeviseResponse;
