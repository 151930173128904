<script>
	import { createEventDispatcher, onMount } from 'svelte';

	export let id;
	export let elements = [];

	const dispatch = createEventDispatcher();

	let dropdownMenu;

	onMount(() => {
		window.addEventListener('click', handleClickOutside);
		return () => {
			window.removeEventListener('click', handleClickOutside);
		};
	})

	function toggleDropdown() {
		if (dropdownMenu) {
			dropdownMenu.classList.toggle('hidden');
			if (!dropdownMenu.classList.contains('hidden')) {
				dropdownMenu.focus();
			}
		}
	}

	function handleAction(action) {
		dispatch('*', action);
		dispatch(action);
	}

	function handleClickOutside(event) {
		if (dropdownMenu && !dropdownMenu.contains(event.target)) {
			dropdownMenu.classList.add('hidden');
		}
	}
</script>

<button
	id="{id}"
	data-dropdown-toggle="dropdownMenu"
	class="inline-flex items-center p-2 text-sm font-medium text-center text-gray-900 bg-white rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-50"
	type="button"
	on:click={toggleDropdown}
>
	<svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 4 15">
		<path d="M3.5 1.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Zm0 6.041a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Zm0 5.959a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z"/>
	</svg>
</button>

<!-- Dropdown menu -->
<div
	class="z-10 absolute mt-2 hidden rounded-lg shadow-lg min-w-52 bg-white ring-1 ring-black ring-opacity-5 right-0"
	on:focusout={toggleDropdown}
	bind:this={dropdownMenu}
>
	<ul
		class="pt-3 px-3 pb-3 overflow-y-auto text-base text-gray-700"
	>
		{#each elements as element}
			<li>
				<div class="flex justify-between px-4 py-2">
					{#if element.component}
						<svelte:component this={element.component}
															{...{...Object.keys(element.props)
																	.filter((key) => key !== 'action')
																	.reduce((obj, key) => { obj[key] = element.props[key]; return obj}, {}),
																action: () => handleAction(element.props.action)
															}} />
					{:else}
						{element}
					{/if}
				</div>
			</li>
		{/each}
	</ul>
</div>
