<script>
	// Svelte
	import { fly, fade } from 'svelte/transition';

	// Store
	import { drawerState, closeDrawer } from '../store.js';
	import { onMount } from 'svelte';

	export let title = '';
	export let classes = '';
	export let maxWidth = 'max-w-md';
	export let width = 'w-screen';
	export let id = 'drawer';

	onMount(() => {
		window.addEventListener('keydown', (e) => {
			if (e.key === 'Escape') {
				closeDrawer();
			}
		});

		return () => {

			window.removeEventListener('keydown', (e) => {
				if (e.key === 'Escape') {
					closeDrawer();
				}
			});
		};

	});
</script>

{#if $drawerState.isOpen && $drawerState.id === id}
	<div
		class="fixed inset-0 z-10"
		aria-labelledby="slide-over-title"
		role="dialog"
		aria-modal="true"
	>
		<!-- Overlay background -->
		<div
			class="fixed inset-0 bg-gray-500 bg-opacity-75"
			aria-hidden="true"
			transition:fade={{ duration: 300 }}
			on:click={closeDrawer}
		></div>

		<div class="fixed inset-y-0 right-0 flex pl-10 {width} {maxWidth} {classes}" transition:fly={{ x: 500, duration: 600 }}>
			<div
				class="h-screen-minus-header mt-16 flex flex-col overflow-y-scroll bg-white py-6 shadow-xl w-full"
			>
				<div class="px-4 sm:px-6">
					<div class="flex items-start justify-between">
						<h2 class="text-base font-semibold leading-6 text-gray-900" id="slide-over-title">
							{title}
						</h2>
						<div class="ml-3 flex h-7 items-center">
							<button
								type="button"
								class="relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
								on:click={closeDrawer}
							>
								<span class="absolute -inset-2.5"></span>
								<span class="sr-only">Close panel</span>
								<svg
									class="h-6 w-6"
									fill="none"
									viewBox="0 0 24 24"
									stroke-width="1.5"
									stroke="currentColor"
									aria-hidden="true"
								>
									<path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
								</svg>
							</button>
						</div>
					</div>
				</div>
				<div class="relative mt-6 flex-1 px-4 sm:px-6">
					<!-- Drawer content -->
					<slot name="drawerContent" data="{$drawerState.data}"> </slot>
				</div>
			</div>
		</div>
	</div>
{/if}
