<script>
	import { ChevronDown, Icon } from 'svelte-hero-icons';
	import { onMount } from 'svelte';

	export let id;
	export let title = 'Button title';
	export let elements = [];
	export let selectedElements = [];
	// export let handle = () => {};

	let menu;
	let button;

	onMount(() => {
		const handleClickOutside = (event) => {
			if (menu && !menu.contains(event.target) && button && !button.contains(event.target)) {
				menu.classList.toggle('hidden', true);
			}
		};
		document.addEventListener('click', handleClickOutside);

		return () => {
			document.removeEventListener('click', handleClickOutside);
		};
	})

	function toggleDropdown() {
		menu.classList.toggle('hidden');
		if (!menu.classList.contains('hidden')) {
			menu.focus();
		}
	}

	function handleSelection(key) {
		selectedElements.update((elements) =>
			elements.includes(key) ? elements.filter((element) => element !== key) : [...elements, key]
		)
	}
</script>

<button
	id="{id}"
	data-dropdown-toggle="dropdownMenu"
	class="inline-flex items-center px-4 py-2 text-sm font-medium text-center text-white bg-primary rounded-lg hover:bg-primaryDark"
	type="button"
	on:click={toggleDropdown}
	bind:this={button}
>
	{title}
	<Icon src={ChevronDown} class="w-5 h-5 ml-2" />
</button>

<!-- Dropdown menu -->
<div
	id="dropdownMenu"
	class="z-50 absolute mt-2 hidden rounded-lg shadow-lg min-w-52 bg-white ring-1 ring-black ring-opacity-5"
	bind:this={menu}
>
	<ul
		class="h-40 pt-3 px-3 pb-3 overflow-y-auto text-base text-gray-700"
		aria-labelledby="dropdownMenu"
	>
		{#each elements as element}
			<li>
				<div class="flex justify-between px-4 py-2">
					<label for="element">
						{element.title}
					</label>
					<input
						type="checkbox"
						id="element"
						class="w-4 h-4 text-primary bg-gray-100 border-gray-300 rounded focus:ring-primary"
						checked={$selectedElements.includes(element.key)}
						on:change={() => handleSelection(element.key)}
					/>
				</div>
			</li>
		{/each}
	</ul>
</div>
