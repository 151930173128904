

/**
 * @class Model
 * @description Model generated from schema
 * @property {string} Intitule - 
 * @property {any} Valeur - 
 */
class InfoLibreModel {
  /**
   * @constructor
   */
  constructor() {
    /** @type {string} */
    this.Intitule = null;
    /** @type {any} */
    this.Valeur = null;
  }
}

export default InfoLibreModel;
