<script>
    import NewClientForm from "../../components/Form/Client/NewClientForm.svelte";
    import { onMount } from 'svelte';
    import { connectedUserHasAccess } from '../../API/Backend/api.js';
    import { Create } from '../../API/Backend/rights/permissions.js';

    onMount(() => {
        connectedUserHasAccess('client', Create);
    });
</script>

<div class="container mx-auto">
    <NewClientForm />
</div>