import { fetchData } from './utils';
import { cleanObject } from './utils';
import { getConnectedUser } from '../Backend/api.js';
import { getCollaborateurClients } from './ServiceQueries.js';

/**
 *
 * @returns {Promise<{data: any, pagination: any}>}
 * @description Get all clients
 */
export async function getClients(
	strictSearchFields,
	strictSearchValues,
	excludeSearchFields,
	excludeSearchValues,
	searchFields,
	searchValues,
	OrderDirection,
	pageNumber = 1,
	pageSize = 10,
	OrderBy,
	filterByUser = true
) {
	searchFields = searchFields ? searchFields + ',CT_Num' : 'CT_Num';
	searchValues = searchValues ? searchValues + ',! 411' : '! 411';
	const queryParams = {
		StrictSearchFields: strictSearchFields,
		StrictSearchValues: strictSearchValues,
		ExcludeSearchFields: excludeSearchFields,
		ExcludeSearchValues: excludeSearchValues,
		SearchFields: searchFields,
		SearchValues: searchValues,
		OrderDirection: OrderDirection,
		PageNumber: pageNumber,
		PageSize: pageSize,
		OrderBy: OrderBy
	};

	let user = await getConnectedUser();
	if (user.sageLink && filterByUser) {
		return await getCollaborateurClients(user, queryParams);
	}

	return await fetchData('/client/LC', queryParams);
}

/**
 * @param {string} reference
 * @returns {Promise<{data: any, pagination: any}>}
 * @description Get a client by its CTNum (reference)
 */
export async function getClientByReference(reference) {
	return await fetchData(`/client/${reference}`);
}

/**
 *
 * @param {string} reference
 * @returns {Promise<{data: any, pagination: any}>}
 * @description Get a client addresses
 */
export async function getClientAddresses(reference) {
	return await fetchData(`/client/AdresseLivraison/${reference}`);
}

/**
 *
 * @param {*} body
 * @returns {Promise<{data: any, pagination: any}>}
 * @description Create a new client
 */
export async function newClient(body) {
	body = cleanObject(body); // Ensure body is cleaned before sending
	return await fetchData('/client/nouveau', {}, 'POST', body); // POST request with body
}
/**
 *
 * @param {*} body
 * @returns {Promise<{data: any, pagination: any}>}
 * @description Create a new client
 */
export async function newClientProspect(body) {
	body = cleanObject(body); // Ensure body is cleaned before sending
	return await fetchData('/Client/nouveau/prospect', {}, 'POST', body); // POST request with body
}

/**
 *
 * @param {string} reference
 * @param {*} body
 * @returns {Promise<{data: any, pagination:any}>}
 * @description Update a client
 */
export async function updateClient(reference, body) {
	body = cleanObject(body);
	return await fetchData(`/client/modifier/${reference}`, {}, 'PATCH', body);
}

/**
 *
 * @param {string} reference
 * @param {*} body
 * @returns {Promise<{data: any, pagination:any}>}
 * @description Update a client's address
 */
export async function updateAdresseLivraison(reference, body){
	body = cleanObject(body);
	return await fetchData(`/client/AdresseLivraison/modifier/${reference}`, {}, 'PATCH', body);
}

/**
 *
 * @param {string} reference
 * @param {*} body
 * @returns {Promise<{data: any, pagination:any}>}
 * @description Update a client's contact
 */
export async function updateContact(reference, body){
	body = cleanObject(body);
	return await fetchData(`/client/contact/modifier/${reference}`, {}, 'PATCH', body);
}
