<script>
	import { Router, Route } from 'svelte-routing';

	// pages for this layout
	import IndexVente from '../views/vente/IndexVente.svelte';
	import NewDevis from '../views/vente/NewDevis.svelte';
	import NotFound from '../views/404.svelte';
	import ListeVente from '../views/vente/ListeVente.svelte';
	import ModificationDocVente from '../components/Form/DocumentVente/ModificationDocVente.svelte'
	import NewFacture from '../views/vente/NewFacture.svelte';
	import Cloture from '../views/vente/Cloture.svelte';

</script>

<Router url="ventes">
	<Route path="/" component={IndexVente} />
	<Route path="liste-des-documents" component={ListeVente} />
	<Route path="creationDE" component={NewDevis} />
	<Route path="creationFA" component={NewFacture} />
	<Route path="cloture" component={Cloture} />
	<Route path=":Category/:DoPiece" component={ModificationDocVente} />
	<Route path="*" component={NotFound} />
</Router>
