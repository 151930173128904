import { fetchData } from './utils.js';

/**
 *
 * @param {string} ctNum
 * @returns {Promise<{any}>}
 * @description Get fournisseur by ctNum
 */
export async function getFournisseurByCtNum(ctNum) {
    return await fetchData('/Fournisseur/' + ctNum ); // GET request
}

/**
 *
 * @returns {Promise<{any}>}
 * @description Get all fournisseur
 */

export async function getAllFournisseur() {
    return await fetchData('/Fournisseur/LF' ); // GET request
}
