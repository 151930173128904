import { cleanObject, fetchData } from './utils.js';

/**
 * Créer une nouvelle facture d'achat
 * @param {*} body
 * @returns {Promise<{data: any, pagination: any}>}
 */
export async function newFactureAchat(body) {
	body = cleanObject(body); // Clean body before sending
	return await fetchData('achat/Facture/nouveau', {}, 'POST', body); // POST request
}