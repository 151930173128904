<script>

	import { createEventDispatcher, onDestroy, onMount } from 'svelte';
	import Steps from './Steps.svelte';
	import { ArrowLeftCircle, Icon } from 'svelte-hero-icons';
	import ButtonPrimary from '../Button/ButtonPrimary.svelte';

	export let steps = [];
	const dispatch = createEventDispatcher();
	let currentStep = 0;
	let isLoading = false;
	$: currentStep;

	onMount(() => {
		currentStep = 0;
		window.addEventListener('keydown', handleKeydown);
		window.addEventListener('final-step', () => currentStep = steps.length - 1);
		window.addEventListener('reset-loading', () => isLoading = false);
	})

	function handleKeydown(event) {
		if (event.ctrlKey) {
			if (event.key === 'ArrowRight' && currentStep < steps.length - 1) {
				currentStep++;
				event.preventDefault();
			} else if (event.key === 'ArrowLeft' && currentStep > 0 && steps[currentStep].label !== 'Récapitulatif') {
				currentStep--;
				event.preventDefault();
			}
		}
	}

	onDestroy(() => {
		window.removeEventListener('keydown', handleKeydown);
		window.removeEventListener('final-step', () => currentStep = steps.length - 1);
		window.removeEventListener('reset-loading', () => isLoading = false);
	})
</script>

<slot {currentStep} />

<div class="flex flex-col gap-4 pl-4 pr-4">
	<div class="flex justify-between mt-8">
		<button
			class= {steps[currentStep].label === 'Récapitulatif' || currentStep === 0 ?
				"bg-gray-200 text-gray-500 rounded-full" :
				"text-primary rounded-full shadow-lg hover:text-primaryDark transition duration-300 ease-in-out"}

			on:click={() => currentStep--}
			disabled={steps[currentStep].label === 'Récapitulatif' || currentStep === 0}
		>
			{#if currentStep === steps.length - 1 && steps[steps.length - 1].label === 'Récapitulatif'}
				<!---->
			{:else}
				<Icon src={ArrowLeftCircle} solid class="h-10 w-10" />
			{/if}
		</button>
		<button
			class={currentStep === steps.length - 1 ?
				"bg-gray-200 text-gray-500 rounded-full" :
				"text-primary rounded-full shadow-lg hover:text-primaryDark transition duration-300 ease-in-out"}

			disabled={currentStep === steps.length - 1}
		>
			{#if currentStep === steps.length - 2 && steps[steps.length - 1].label === 'Récapitulatif'}
				<ButtonPrimary x="px-6" py="py-2" text="text-lg" isLoading={isLoading} on:click={() => {
					isLoading = true;
					dispatch('create', {});
				}}>Créer</ButtonPrimary>
			{:else if currentStep === steps.length - 1 && steps[steps.length - 1].label === 'Récapitulatif'}
				<!---->
			{:else}
				<ButtonPrimary cx="px-6" py="py-2" text="text-lg" on:click={() => currentStep++}>Continuer</ButtonPrimary>
			{/if}
		</button>
	</div>

	<Steps {steps} {currentStep} />
</div>
