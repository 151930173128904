

/**
 * @class Model
 * @description Représentation d'un pays dans Sage
 * @property {string} PA_Intitule - 
 * @property {string} PA_Code - 
 * @property {string} PA_CodeEdi - 
 * @property {number} PA_Assurance - 
 * @property {number} PA_Transport - 
 * @property {string} PA_CodeISO2 - 
 * @property {number} PA_SEPA - 
 * @property {number} PA_No - 
 */
class PaysResponse {
  /**
   * @constructor
   */
  constructor() {
    /** @type {string} */
    this.PA_Intitule = null;
    /** @type {string} */
    this.PA_Code = null;
    /** @type {string} */
    this.PA_CodeEdi = null;
    /** @type {number} */
    this.PA_Assurance = null;
    /** @type {number} */
    this.PA_Transport = null;
    /** @type {string} */
    this.PA_CodeISO2 = null;
    /** @type {number} */
    this.PA_SEPA = null;
    /** @type {number} */
    this.PA_No = null;
  }
}

export default PaysResponse;
