<script>
	import { createEventDispatcher } from 'svelte';

	export let label = '';
	export let helpText = '';
	export let checked = false;

	const dispatch = createEventDispatcher();
</script>
<div class="flex pb-1">
	<div class="flex items-center h-5">
		<input id="checkbox"
					 aria-describedby="checkbox-text"
					 type="checkbox"
					 bind:checked
					 on:change={() => dispatch('change', checked)}
					 class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2"
		>
	</div>
	<div class="ms-2 text-sm">
		<label for="checkbox" class="font-medium text-gray-900">{label}</label>
		{#if helpText}
			<p id="checkbox-text" class="text-xs font-normal text-gray-500">{helpText}</p>
		{/if}
		</div>
</div>