import { del, get, post } from '../api.js';

export async function getUsersRights(strictSearchFields,
	strictSearchValues,
	excludeSearchFields,
	excludeSearchValues,
	searchFields,
	searchValues,
	OrderDirection,
	pageNumber = 1,
	pageSize = 25,
	OrderBy) {
	const queryParams = {
		StrictSearchFields: strictSearchFields,
		StrictSearchValues: strictSearchValues,
		ExcludeSearchFields: excludeSearchFields,
		ExcludeSearchValues: excludeSearchValues,
		SearchFields: searchFields,
		SearchValues: searchValues,
		OrderDirection: OrderDirection,
		PageNumber: pageNumber,
		PageSize: pageSize,
		OrderBy: OrderBy
	};
	return await get('/users-rights', queryParams);
}

export async function addRightToUser(user_id, right_id, permission) {
	return await post('/users-rights', { user_id, right_id, permission });
}

export async function removeRightFromUser(user_id, right_id) {
	return await del('/users-rights', { user_id, right_id });
}