<script>
	import Tooltip from '../Tooltip/Tooltip.svelte';

	let tooltipVisible = false;

	function showTooltip() {
		tooltipVisible = !tooltipVisible;
	}
</script>

<div class="flex-col gap-2 items-center">
	<button
		type="button"
		class="flex justify-center items-center w-5 h-5 text-blue-700 hover:text-white border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-full text-sm text-center dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:hover:bg-blue-500 dark:focus:ring-blue-800"
		on:mouseenter={showTooltip}
		on:mouseleave={showTooltip}>
		i
	</button>

	{#if tooltipVisible}
		<Tooltip>
			<slot></slot>
		</Tooltip>
	{/if}
</div>

