<script>
	// COMPONENTS
	import Input from '../../Input/Input.svelte';
	import DoubleInput from '../../Input/DoubleInput.svelte';
	import Select from '../../Select/Select.svelte';
	import ButtonPrimary from '../../Button/ButtonPrimary.svelte';
	// ARTICLE
	import { getInfoLibres, newArticle } from '../../../API/API_SAGE/ArticleQueries.js';
	import ArticleNewModel from '../../../API/API_SAGE/model/ArticleNewModel.js';
	import InfoLibreResponse from '../../../API/API_SAGE/model/InfoLibreResponse.js';
	// FAMILLE
	import { getFamilles } from '../../../API/API_SAGE/FamilleQueries.Js';
	import FamilleResponse from '../../../API/API_SAGE/model/FamilleResponse.js';
	// PAYS
	import { getPays } from '../../../API/API_SAGE/PaysQueries.js';
	import PaysResponse from '../../../API/API_SAGE/model/PaysResponse.js';
	//DEVISES
	import { getDevises } from '../../../API/API_SAGE/DeviseQueries.js';
	import DeviseResponse from '../../../API/API_SAGE/model/DeviseResponse.js';
	//CATEGORIE TARIFAIRE
	import { getCategorieTarifaire } from '../../../API/API_SAGE/CategorieTarifaireQueries.js';
	import CategorieTarifaireResponse from '../../../API/API_SAGE/model/CategorieTarifaireResponse.js';
	//FOURNISSEURS
	import { getAllFournisseur, getFournisseurByCtNum } from '../../../API/API_SAGE/FournisseurQueries.js'
	import Fournisseur from '../../../API/API_SAGE/model/Fournisseur.js'
	//ENUM
	import { unitePoidsEnum } from '../../../API/API_SAGE/UnitePoidsEnum.js';

	// SVELTE
	import { onMount } from 'svelte';
	// STORE
	import { addToast } from '../../../store.js';

	// VARIABLES
	let infoLibres = new InfoLibreResponse();

	/**
	 * @type {FamilleResponse[]}
	 */
	let codeFamilles = new FamilleResponse();

	/**
	 * @type {PaysResponse[]}
	 */
	let pays = new PaysResponse();

	/**
	 * @type {DeviseResponse}
	 */
	let devises = new DeviseResponse();

	/**
	 * @type {CategorieTarifaireResponse[]}
	 */
	let categoriesTarifaire = new CategorieTarifaireResponse();

	/**
	 * @type {Fournisseur[]}
	 */
	let fournisseurs = new Fournisseur();

	// MODELS

	/**
	 * @type {ArticleNewModel}
	 */
	let articleBody = new ArticleNewModel();
	let newFournisseur;
	let newCategorieTarifaire;
	let newCoeff = 2;


	// SELECT OPTIONS
	let codeFamillesOptions = [{ value: '', label: '' }];
	let paysOptions = [{ value: '', label: '' }];
	let devisesOptions = [{ value: '', label: '' }];
	let categoriesTarifaireOptions = [{ value: '', label: '' }];
	let fournisseursOptions = [{ value: '', label: '' }];

	// ON MOUNT
	onMount(async () => {
		articleBody.prixAchat = 0;
		// GET Sage Data
		infoLibres = await getInfoLibres().then((response) => response.data);
		codeFamilles = await getFamilles(['FA_Type'], [0]).then((response) => response.data);
		pays = await getPays().then((response) => response.data);
		devises = await getDevises().then((response) => response.data);
		categoriesTarifaire = await getCategorieTarifaire().then((response) => response.data);
		fournisseurs = await getAllFournisseur().then((response) => response.data);

		// CONSTRUCT OPTIONS
		constructCodeFamilleOptions();
		constructPaysOptions();
		constructDevisesOptions();
		constructCategoriesTarifaireOptions();
		constructFournisseursOptions();

		// INIT MODEL
		articleBody.infoLibres = [];
		articleBody.codeFamille = codeFamillesOptions[0].value;
		articleBody.typeFamille = 'Detail';
		articleBody.Pays = 'France';
	});

	// FUNCTIONS
	function constructCodeFamilleOptions() {
		codeFamillesOptions = codeFamilles.map((famille) => {
			return {
				value: famille.FA_CodeFamille,
				label: famille.FA_Intitule
			};
		});
	}

	function constructPaysOptions() {
		paysOptions = pays.map((item) => {
			return {
				value: item.PA_Intitule,
				label: item.PA_Intitule
			};
		});
	}

	function constructDevisesOptions() {
		devisesOptions = devises.map((devise) => {
			return {
				value: devise.D_Intitule,
				label: devise.D_Intitule
			};
		});
	}

	function constructCategoriesTarifaireOptions() {
		categoriesTarifaireOptions = categoriesTarifaire.map((categorie) => {
			return {
				value: categorie.CT_Intitule,
				label: categorie.CT_Intitule
			}
		});
		categoriesTarifaireOptions = [{
			value: "Aucune categorie",
			label: "Aucune categorie"
		}, ...categoriesTarifaireOptions];
	}

	function constructFournisseursOptions() {
		fournisseursOptions = fournisseurs.map((fournisseur) => {
			return {
				value: fournisseur.CT_Num,
				label: fournisseur.CT_Intitule
			}
		});
		fournisseursOptions = [{
			value: "Aucun fournisseur",
			label: "Aucun fournisseur"
		}, ...fournisseursOptions];
	}
	function addInfoLibre(event, CB_Name) {
		if (articleBody.InfoLibres.find((infoLibre) => infoLibre.Intitule === CB_Name) != undefined) {
			articleBody.InfoLibres.find((infoLibre) => infoLibre.Intitule === CB_Name).Valeur =
				event.detail;
			return;
		}
		articleBody.InfoLibres.push({ Intitule: CB_Name, Valeur: event.detail });
	}

	function addCategorieTarifaire() {
		if (newCategorieTarifaire !== "Aucune categorie") {
			articleBody = {
				... articleBody,
				ArticleTarifCategories: [
					...articleBody.ArticleTarifCategories,
					{
						Intitule: newCategorieTarifaire,
						Coefficient: newCoeff,
						PrixVente: articleBody.PrixAchat * newCoeff
					}
				]
			}
			categoriesTarifaireOptions = categoriesTarifaireOptions.filter((categorieTarifaire) => categorieTarifaire.value !== newCategorieTarifaire)
		}
	}

	function removeCategorieTarifaire(index, categorieTarifaireIntitule) {
		categoriesTarifaireOptions.push({
			value: categorieTarifaireIntitule,
			label: categorieTarifaireIntitule
		});
		articleBody = {
			...articleBody,
			ArticleTarifCategories: articleBody.ArticleTarifCategories.filter((_, i) => i !== index)
		};
	}

	async function addFournisseur() {
		if (newFournisseur !== "Aucun fournisseur") {
			let tempFournisseur = await getFournisseurByCtNum(newFournisseur);
			articleBody = {
				...articleBody,
				Fournisseurs: [
					...articleBody.Fournisseurs,
					{
						NumFournisseur: newFournisseur,
						IntituleFournisseur: tempFournisseur.data.CT_Intitule,
						IntituleDevise: 'Euro',
						prixAchat: 0
					}
				]
			}
			fournisseursOptions = fournisseursOptions.filter((fournisseur) => fournisseur.value !== newFournisseur)
		}
	}

	async function removeFournisseur(index, numFournisseur) {
		let fournisseur = await getFournisseurByCtNum(numFournisseur);
		fournisseursOptions.push({
			value: fournisseur.data.CT_Num,
			label: fournisseur.data.CT_Intitule
		});
		articleBody = {
			...articleBody,
			Fournisseurs: articleBody.Fournisseurs.filter((_, i) => i !== index)
		};
	}

	async function createArticle(event) {
		event.preventDefault(); // Prevent default form submission
		articleBody.reference = articleBody.reference.toUpperCase();
		try {
			let response = await newArticle(articleBody);
			if (response.data.status == 400) {
				addToast(`Erreur lors de la création de l'article. ${response.data.detail}`, 'bg-red-500');
				return;
			} else if (response.data.status == 200) {
				addToast(response.data.detail, 'bg-green-500');
				window.location.reload();
				// event.target.reset();
			}
		} catch (error) {
			addToast("Erreur innatendue lors de la création de l'article", 'bg-red-500');
		}
	}
</script>
<div class="text-card-foreground pb-4">
	<div class="flex flex-col space-y-1.5 p-6">
		<h1 class="whitespace-nowrap text-2xl font-semibold leading-none tracking-tight">
			Créer un article
		</h1>
	</div>
	<!-- Wrap everything in a form -->
	<form class="p-6 space-y-4" on:submit={createArticle}>
		<div class="flex items-baseline justify-between">
			<h2 class="whitespace-nowrap text-xl font-semibold leading-none tracking-tight">
				Identification
			</h2>
			<hr class="flex-grow ml-4 border-t border-gray-300" />
		</div>

		<div class="grid grid-cols-2 gap-4">
			<div class="space-y-2">
				<Input
					id="ref"
					placeholder="Réf"
					label="Référence"
					bind:value={articleBody.reference}
					required
					minlength="1"
					maxlength="18"
				/>
			</div>
			<div class="space-y-2">
				<Input
					id="designation"
					placeholder="Désignation"
					label="Désignation"
					bind:value={articleBody.Design}
					required
					maxlength="69"
				/>
			</div>
		</div>

		<div class="grid grid-cols-2 gap-4">
			<div class="space-y-2">
				<Select
					label="Code Famille"
					options={codeFamillesOptions}
					bind:value={articleBody.CodeFamille}
					required
				/>
			</div>
			<div class="space-y-2">
				<Input id="catalogue" placeholder="Catalogue" label="Catalogue" maxlength="20" />
			</div>
		</div>
		<div class="grid grid-cols-2 gap-4">
			<div class="space-y-2">
				<Select
					label="Pays"
					options={paysOptions}
					bind:value={articleBody.Pays}
				/>
			</div>
			<div class="space-y-2">
				<Input
					id="codeFiscal"
					placeholder="Code Fiscal"
					label="Code Fiscal"
					bind:value={articleBody.CodeFiscal}
					maxlength="69"
				/>
			</div>
		</div>
		<div class="grid grid-cols-2 gap-4">
			<div class="space-y-2">
				<Input
					id="codeBarre"
					placeholder="Code-Barre"
					label="Code-Barre"
					bind:value={articleBody.CodeBarre}
					maxlength="69"
				/>
			</div>
			<div class="space-y-2">
				<Input
					id="refArticleSubstitution"
					placeholder="Référence article de substitution"
					label="Référence article de substitution"
					bind:value={articleBody.RefArticleSubstitution}
					maxlength="69"
				/>
			</div>
		</div>
		<div class="flex items-baseline justify-between">
			<h2 class="whitespace-nowrap text-xl font-semibold leading-none tracking-tight">
				Prix Unitaire
			</h2>
			<hr class="flex-grow ml-4 border-t border-gray-300" />
		</div>
		<div class="grid grid-cols-2 gap-4">
			<div class="space-y-2">
				<DoubleInput
					id="pu-vente"
					placeholder="10.00"
					label="PU Vente"
					bind:value={articleBody.PrixVente}
					type="number"
					min="0.1"
					step="0.1"
					required
				/>
			</div>
			<div class="space-y-2">
				<DoubleInput
					id="pu-achat"
					placeholder="10.00"
					label="PU Achat"
					bind:value={articleBody.PrixAchat}
					type="number"
					min="0.1"
					step="0.1"
				/>
			</div>
		</div>
		<div class="flex items-baseline justify-between">
			<h2 class="whitespace-nowrap text-xl font-semibold leading-none tracking-tight">
				Poids
			</h2>
			<hr class="flex-grow ml-4 border-t border-gray-300" />
		</div>
		<div class="grid grid-cols-2 gap-4">
			<div class="space-y-2">
				<Select
					label="Unite poids"
					options={unitePoidsEnum}
					bind:value={articleBody.UnitePoids}
				/>
			</div>
		</div>
		<div class="grid grid-cols-2 gap-4">
			<div class="space-y-2">
				<DoubleInput
					id="poidsNet"
					placeholder="10.00"
					label="Poids Net"
					bind:value={articleBody.PoidsNet}
					type="number"
					min="0.1"
					step="0.1"
				/>
			</div>
			<div class="space-y-2">
				<DoubleInput
					id="poidsBrut"
					placeholder="10.00"
					label="Poids Brut"
					bind:value={articleBody.PoidsBrut}
					type="number"
					min="0.1"
					step="0.1"
				/>
			</div>
		</div>
		<div class="flex items-baseline justify-between">
			<h2 class="whitespace-nowrap text-xl font-semibold leading-none tracking-tight">
				Langues
			</h2>
			<hr class="flex-grow ml-4 border-t border-gray-300" />
		</div>
		<div class="grid grid-cols-2 gap-4">
			<div class="space-y-2">
				<Input
					id="langue1"
					placeholder="Langue 1"
					label="Langue 1"
					bind:value={articleBody.Langue1}
					maxlength="69"
				/>
			</div>
			<div class="space-y-2">
				<Input
					id="langue2"
					placeholder="Langue 2"
					label="Langue 2"
					bind:value={articleBody.Langue2}
					maxlength="69"
				/>
			</div>
		</div>
		<div class="flex items-baseline justify-between">
			<h2 class="whitespace-nowrap text-xl font-semibold leading-none tracking-tigh">
				Infos Libres
			</h2>
			<hr class="flex-grow ml-4 border-t border-gray-300" />
		</div>

		<div class="grid grid-cols-2 gap-4">
			{#each infoLibres as infoLibre}
				<div class="space-y-2">
					<Input
						id={infoLibre.CB_Name}
						label={infoLibre.CB_Name}
						on:input={(event) => {
							addInfoLibre(event, infoLibre.CB_Name);
						}}
						minlength="1"
					/>
				</div>
			{/each}
		</div>
		<div class="flex items-baseline justify-between">
			<h2 class="whitespace-nowrap text-xl font-semibold leading-none tracking-tigh">
				Catégories Tarifaire
			</h2>
			<hr class="flex-grow ml-4 border-t border-gray-300" />
		</div>
		<div class="grid grid-cols-2 gap-4">
			<div class="space-y-2">
				<Select
					label="Intitule categorie tarifaire"
					options={categoriesTarifaireOptions}
					bind:value={newCategorieTarifaire}
				/>
			</div>
			<div class="space-y-2">
				<DoubleInput
					id="coefficient"
					placeholder="2"
					label="Coefficient"
					bind:value={newCoeff}
					type="number"
					min="0.1"
					step="0.1"
				/>
			</div>
		</div>
		<div>
			<ButtonPrimary on:click={addCategorieTarifaire} type="button">Ajouter la categorie tarifaire</ButtonPrimary>
		</div>
		<div class="overflow-auto custom-scrollbar rounded-lg shadow-md">
			{#if articleBody.ArticleTarifCategories?.length > 0}
				<table class="min-w-full divide-y divide-gray-200">
					<thead class="bg-gray-200">
						<tr>
							<th
								class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
							>
								Intitule
							</th>
							<th
								class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
							>
								Coefficient
							</th>
							<th
								class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
							>
								Prix de vente
							</th>
							<th
								class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
							>
								Actions
							</th>
						</tr>
					</thead>
					<tbody class="bg-white divide-y divide-gray-200">
						{#each articleBody.ArticleTarifCategories as row, index}
							<tr class="hover:bg-gray-50">
								<td class="px-6 py-4 text-sm text-gray-700 whitespace-nowrap">
									{row.Intitule}
								</td>
								<td class="px-6 py-4 text-sm text-gray-700 whitespace-nowrap">
									<DoubleInput
										id="coefficient"
										placeholder="10.00"
										label=""
										bind:value={row.Coefficient}
										min="0.1"
										step="0.1"
									/>
								</td>
								<td class="px-6 py-4 text-sm text-gray-700 whitespace-nowrap">
									<DoubleInput
										id="prixVente"
										placeholder="10.00"
										label=""
										bind:value={row.PrixVente}
										min="0.1"
										step="0.1"
									/>
								</td>
								<td class="px-6 py-4 text-sm text-gray-700 whitespace-nowrap">
									<button
										class="text-red-600 hover:text-red-900"
										on:click={() => removeCategorieTarifaire(index, row.Intitule)}>Supprimer</button
									>
								</td>
							</tr>
						{/each}
					</tbody>
				</table>
			{/if}
		</div>

		<div class="flex items-baseline justify-between">
			<h2 class="whitespace-nowrap text-xl font-semibold leading-none tracking-tigh">
				Fournisseur
			</h2>
			<hr class="flex-grow ml-4 border-t border-gray-300" />
		</div>
		<div class="grid grid-cols-2 gap-4">
			<div class="space-y-2">
				<Select
					label="Intitule fournisseur"
					options={fournisseursOptions}
					bind:value={newFournisseur}
				/>
			</div>
		</div>
		<div>
			<ButtonPrimary on:click={addFournisseur} type="button">Ajouter le fournisseur</ButtonPrimary>
		</div>
		{#if articleBody.Fournisseurs?.length > 0}
			<div class="overflow-visible custom-scrollbar shadow-md rounded-lg">
				<table class="min-w-full divide-y divide-gray-200">
					<thead class="bg-gray-200">
						<tr>
							<th
								class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
							>
								Intitule
							</th>
							<th
								class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
							>
								Prix achat
							</th>
							<th
								class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
							>
								Devise
							</th>
							<th
								class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
							>
								Actions
							</th>
						</tr>
					</thead>
					<tbody class="bg-white divide-y divide-gray-200">
						{#each articleBody.Fournisseurs as row, index}
							<tr class="hover:bg-gray-50">
								<td class="px-6 py-4 text-sm text-gray-700 whitespace-nowrap">
									{row.IntituleFournisseur}
								</td>
								<td class="px-6 py-4 text-sm text-gray-700 whitespace-nowrap">
									<DoubleInput
										id="prixAchat"
										bind:value={row.prixAchat}
										placeholder="10.00"
										label=""
										min="0.1"
										step="0.1"
									/>
								</td>
								<td class="px-6 py-4 text-sm text-gray-700 whitespace-nowrap">
									<Select
										label=""
										options={devisesOptions}
										bind:value={row.IntituleDevise}
									/>
								</td>
								<td class="px-6 py-4 text-sm text-gray-700 whitespace-nowrap">
									<button
										class="text-red-600 hover:text-red-900"
										on:click={() => removeFournisseur(index, row.NumFournisseur)}>Supprimer</button
									>
								</td>
							</tr>
						{/each}
					</tbody>
				</table>
			</div>
		{/if}
		<div class="flex items-center w-full justify-center">
			<!-- Submit button now tied to the form submission -->
			<ButtonPrimary px="px-6" py="py-2" text="text-lg" type="submit">Créer</ButtonPrimary>
		</div>
	</form>
</div>