

/**
 * @class Model
 * @description Model generated from schema
 * @property {string} Intitule - 
 * @property {string} Adresse - 
 * @property {string} CodePostal - 
 * @property {string} Complement - 
 * @property {string} CodeRegion - 
 * @property {string} Ville - 
 * @property {string} Pays - 
 * @property {boolean} GerePays - 
 * @property {boolean} GereCodeRegion - 
 * @property {string} Contact - 
 * @property {string} Telephone - 
 * @property {string} Telecopie - 
 * @property {string} Email - 
 */
class AdresseLivraisonModel {
  /**
   * @constructor
   */
  constructor() {
    /** @type {string} */
    this.Intitule = null;
    /** @type {string} */
    this.Adresse = null;
    /** @type {string} */
    this.CodePostal = null;
    /** @type {string} */
    this.Complement = null;
    /** @type {string} */
    this.CodeRegion = null;
    /** @type {string} */
    this.Ville = null;
    /** @type {string} */
    this.Pays = null;
    /** @type {boolean} */
    this.GerePays = null;
    /** @type {boolean} */
    this.GereCodeRegion = null;
    /** @type {string} */
    this.Contact = null;
    /** @type {string} */
    this.Telephone = null;
    /** @type {string} */
    this.Telecopie = null;
    /** @type {string} */
    this.Email = null;
  }
}

export default AdresseLivraisonModel;
