<script>
    import { createEventDispatcher, onMount } from 'svelte';
    import InverseLoadingDots from '../Loading/InverseLoadingDots.svelte';

    export let px = 'px-2';
    export let py = 'py-1';
    export let text = 'text-xs';
    export let content = 'Button text';
    export let type = 'button';
    export let isLoading = false;
    export let disabled = false;
    export let classes = "";

    const dispatch = createEventDispatcher();

    onMount(() => {
        classes.split(" ").forEach((property) => {
            if (property.includes("text")) {
                text = property;
            }

            if (property.includes("px")) {
                px = property;
            }

            if (property.includes("py")) {
                py = property;
            }
        });
    });
    function handleClick(event) {
        dispatch('click', event);
    }
</script>

<button
    on:click={handleClick}
    type={type}
    class="rounded bg-primary {classes} {px} {py} {text} font-semibold text-white shadow-sm hover:bg-primaryDark focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary"
    disabled={disabled ? disabled : isLoading}
>
    {#if isLoading}
        <div class="flex justify-center items-center">
            <InverseLoadingDots />
        </div>
    {:else}
        <slot>{content}</slot>
    {/if}
</button>
