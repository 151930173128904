import { fetchData } from './utils.js';

/**
 *
 * @returns {Promise<{data: any}>}
 * @description Get all devises
 *
 */

export async function getDevises() {
	return await fetchData('/Devise/listeDevise');
}