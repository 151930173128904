<script>
	let location;

	$: {
		location = window.location.pathname.split('/').filter(Boolean);
	}

	function getBreadcrumbs(location) {
		const breadcrumbs = [];
		let path = '';

		if (!(location[0] === 'accueil')) {
			breadcrumbs.push({ href: '/accueil', label: 'Home' });
		}
		location.forEach((segment) => {
			if(segment === "ventes" || segment === "devis" || segment === "facture") {
				path += `/${segment}`;
				breadcrumbs.push({ href: "/ventes/liste-des-documents", label: segment });
			} else if(segment === "clients") {
				path += `/${segment}`;
				breadcrumbs.push({ href: "/clients/liste", label: segment });
			} else {
				path += `/${segment}`;
				breadcrumbs.push({ href: path, label: segment });
			}
		});
		return breadcrumbs;
	}
</script>

<nav class="flex" aria-label="Breadcrumb">
	<ol role="list" class="flex items-center space-x-4">
		{#each getBreadcrumbs(location) as item, index}
			<li>
				<div class="flex items-center">
					{#if index === 0}
						<a href={item.href} class="text-gray-400 hover:text-gray-500">
							<svg
								class="h-5 w-5 flex-shrink-0"
								viewBox="0 0 20 20"
								fill="currentColor"
								aria-hidden="true"
							>
								<path
									fill-rule="evenodd"
									d="M9.293 2.293a1 1 0 011.414 0l7 7A1 1 0 0117 11h-1v6a1 1 0 01-1 1h-2a1 1 0 01-1-1v-3a1 1 0 00-1-1H9a1 1 0 00-1 1v3a1 1 0 01-1 1H5a1 1 0 01-1-1v-6H3a1 1 0 01-.707-1.707l7-7z"
									clip-rule="evenodd"
								/>
							</svg>
							<span class="sr-only">{item.label}</span>
						</a>
					{:else}
						<svg
							class="h-5 w-5 flex-shrink-0 text-gray-400"
							viewBox="0 0 20 20"
							fill="currentColor"
							aria-hidden="true"
						>
							<path
								fill-rule="evenodd"
								d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
								clip-rule="evenodd"
							/>
						</svg>
						{#if index === location.length - 1}
							<a
								href={item.href}
								class="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
								aria-current="page">{item.label.charAt(0).toUpperCase() + item.label.slice(1)}</a
							>
						{:else}
							<a href={item.href} class="ml-4 text-sm font-medium text-primary hover:text-gray-700"
								>{item.label.charAt(0).toUpperCase() + item.label.slice(1)}</a
							>
						{/if}
					{/if}
				</div>
			</li>
		{/each}
	</ol>
</nav>
