<script>
	// Components
	import Combobox from '../../Combobox/Combobox.svelte';
	import ButtonPrimary from '../../Button/ButtonPrimary.svelte';
	import Select from '../../Select/Select.svelte';

	//Queries
	import { getUsers } from '../../../API/Backend/rights/UserQueries.js';
	import { getRights } from '../../../API/Backend/rights/RightsQueries.js';
	import { addRightToUser } from '../../../API/Backend/rights/UserRightsQueries.js';

	//Svelte
	import { onMount } from 'svelte';

	//Utils
	import { allPermissions } from '../../../API/Backend/rights/permissions.js';
	import { addToast } from '../../../store.js';
	import { ucfirst } from '../../../utils.js';

	const permissions = allPermissions.map(perm => {
		return { label: perm.name, value: perm.value }
	});
	
	let users = [{ label: '', value: '' }];
	let rights = [{ label: '', value: '' }];
	let user = '';
	let right = '';
	let permission = permissions[0].value;

	onMount(async () => {
		users = await getUsers().then(res => res.map(user => ({ label: user.name, value: user.id })));
		rights = await getRights().then(res => res.map(right => ({ label: ucfirst(right.name).replace('_', ' '), value: right.id })));
		user = users[0].value;
		right = rights[0].value;
	});

	async function addRight() {
		let response = await addRightToUser(user, right, permission);
		if (response.statusCode && response.statusCode !== 200) {
			addToast(`Erreur lors de l'ajout du droit ! ${response.message}`, 'bg-red-500');
			return;
		}
		addToast('Droit ajouté avec succès !', 'bg-green-500');
		window.dispatchEvent(new CustomEvent('refresh'));
	}
</script>

<div class="grid grid-cols-3 gap-2 m-2">
	<Combobox id="user"
						label="Utilisateur"
						options={users}
						bind:value={user} />

	<Combobox id="right"
						label="Droit"
						options={rights}
						bind:value={right} />

	<Select id="permission"
					label="Permission"
					multiple
					options={permissions}
					bind:value={permission} />
</div>

<div class="flex justify-center py-2">
	<ButtonPrimary on:click={addRight}>
		<div class="m-2">
			Ajouter un droit
		</div>
	</ButtonPrimary>
</div>

