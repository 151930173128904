import { fetchData } from './utils.js';

/**
 *
 * @returns {Promise<{data: any}>}
 * @description Get all devises
 *
 */

export async function getCategorieTarifaire() {
	return await fetchData('/CategorieTarifaire/listeCT');
}