<script>
    import { createEventDispatcher } from 'svelte';

    export let id = '';
    export let label = '';
    export let value = '';
    export let placeholder = '';
    export let classes = '';
    export let required = false;
    export let min = 0;
    export let max;
    export let timing = 5000;
    export let pattern = "[0-9]*[.,]?[0-9]*";
    export let step = '0.01';

    const handleInput = () => {
      dispatch('input', value);

      clearTimeout(typingTimer);

      typingTimer = setTimeout(() => {
        if (value < min) {
          value = min;
        }
      }, timing);
    };
    const dispatch = createEventDispatcher();

    let typingTimer;
</script>

  <div>
    {#if label}
      <label class="font-medium" for={id}>
        {label}
      </label>
    {/if}
    <input
      id={id}
      type='number'
      step={step}
      class="cursor-default w-full rounded-md border-none bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-primary sm:text-sm sm:leading-6 min-w-28 {classes}"
      bind:value
      placeholder={placeholder}
      required={required}
      min={min}
      max={max}
      pattern={pattern}
      on:input={handleInput}
    />
  </div>

  <style>
    label {
      display: block;
    }
  </style>
