import { fetchData } from './utils.js';
import FactureModel from './model/FactureModel.js';

/**
 *
 * @param {StocksModel} model
 * @returns {Promise<{data: any, type: string}>}
 * @description Generate a pdf report
 */
export async function generateStocksMouvement(model){
	return await fetchData('/Report/stocks/mouvements', {}, 'POST', model)
}

/**
 *
 * @param {FactureModel | DevisModel} model
 * @returns {Promise<{data: any, type: string}>}
 * @description Generate a facture pdf report
 */
export async function generateDocVentes(model){
	if(model instanceof FactureModel){
		return await fetchData('/Report/ventes/facture', {}, 'POST', model)
	}

	return await fetchData('/Report/ventes/devis', {}, 'POST', model)
}