<script>
	// Components
	import Input from '../../Input/Input.svelte';
	import TelInput from '../../Input/TelInput.svelte';
	import MailInput from '../../Input/MailInput.svelte';
	import ButtonPrimary from '../../Button/ButtonPrimary.svelte';
	import Select from '../../Select/Select.svelte';
	import FormGroup from '../FormGroup.svelte';
	import InfoLibreModel from '../../../API/API_SAGE/model/InfoLibreModel.js';
	import CheckBox from '../../Input/CheckBox.svelte';

	// Client
	import AdresseLivraisonModel from '../../../API/API_SAGE/model/AdresseLivraisonModel.js';
	import ContactNewModel from '../../../API/API_SAGE/model/ContactNewModel.js';
	import ClientNewModel from '../../../API/API_SAGE/model/ClientNewModel.js';
	import { getClientByReference, newClient, newClientProspect } from '../../../API/API_SAGE/ClientQueries.js';

	// Store
	import { addToast } from '../../../store.js';
	import { onMount } from 'svelte';
	import { linkClientToCollabo } from '../../../API/API_SAGE/utils.js';
	import { openUrlInCurrentTab } from '../../../utils.js';
	import Combobox from '../../Combobox/Combobox.svelte';
	import { getCommunesFromCodePostal } from '../../../API/API_SAGE/ServiceQueries.js';

	// Constants
	const genderOptions = [
		{ value: 'M', label: 'M' },
		{ value: 'Mme', label: 'Mme' },
		{ value: 'M et Mme', label: 'M et Mme' },
		{ value: 'M ou Mme', label: 'M ou Mme' },
		{ value: 'Indetermine', label: 'Indéterminé' },
	];
	const civiliteOptions = [
		{ value: 'M.', label: 'M' },
		{ value: 'Mme', label: 'Mme' },
		{ value: 'Mlle', label: 'Mlle' },
	];

	// Variables
	let clientBody = new ClientNewModel();
	let secondaryAddress = new AdresseLivraisonModel();
	let contact = new ContactNewModel();
	let prospect = false;
	let isCreationFromUser = false;
	let name = '';
	let surname = '';
	let urssaffapi = false;
	let isLoading = false;
	let disabled = false;
	let coordonneesCommunes = [{value: '', label: ''}];
	let secondaryCoordonneesCommunes = [{value: '', label: ''}];


	// Functions
	onMount(() => {
		clientBody.Qualite = 'M';
		const urlParams = new URLSearchParams(window.location.search);
		isCreationFromUser = urlParams.get('isCreationFromUser');
		if (isCreationFromUser) {
			name = urlParams.get('name');
			surname = urlParams.get('surname');
			clientBody.Adresse = urlParams.get('address');
			clientBody.Ville = urlParams.get('city');
			clientBody.CodePostal = urlParams.get('zipcode');
		}
	})

	async function createClient(event) {
		event.preventDefault();
		isLoading = true;
		let response;

		try {
			let infoLibreName = new InfoLibreModel();
			infoLibreName.Intitule = 'LS_NOM';
			infoLibreName.Valeur = name;
			clientBody.InfoLibres.push(infoLibreName);

			let infoLibreSurname = new InfoLibreModel();
			infoLibreSurname.Intitule = 'LS_PRENOMS';
			infoLibreSurname.Valeur = surname;
			clientBody.InfoLibres.push(infoLibreSurname);

			if (urssaffapi) {
				let infoLibreUrsaff = new InfoLibreModel();
				infoLibreUrsaff.Intitule = 'LS_API';
				infoLibreUrsaff.Valeur = '0';
				clientBody.InfoLibres.push(infoLibreUrsaff);
			}

			if (secondaryAddress.Adresse) {
				secondaryAddress.Intitule = 'Adresse secondaire';
				clientBody.Adresses.push(secondaryAddress);
			}

			if (contact.Nom || contact.Prenom || contact.Email || contact.Telephone) {
				contact.Civilite = contact.Civilite ?? 'M.';
				clientBody.Contacts.push(contact);
			}

			clientBody.Pays = 'France';
			clientBody.CompteCollectif = '4110000000';
			clientBody.Intitule = `${clientBody.Qualite} ${name} ${surname}`;

			response = prospect ? await newClientProspect(clientBody) : await newClient(clientBody);

			if (response.data.status !== 200) {
				addToast(response.data.detail, 'bg-red-500');
				isLoading = false;
				return;
			}

			if (isCreationFromUser) {
				addToast(response.data.detail + " Attendez svp qu'il vous soit assigné.", 'bg-green-500');
			} else {
				addToast(response.data.detail, 'bg-green-500');
				resetForm();
				return;
			}
		} catch (error) {
			addToast(`Erreur innatendue ${error}`, 'bg-red-500');
			isLoading = false;
		} finally {
			clientBody.InfoLibres = [];
			clientBody.Adresses = [];
			clientBody.Contacts = [];
		}

		if (isCreationFromUser) {
			let clientSage = await getClientByReference(response.data.detail.split(' ')[1]).then(res => res.data);
			await linkClientToCollabo(clientSage);
			disabled = true;
			setTimeout(() => {
				openUrlInCurrentTab('/clients/liste');
			}, 4000);
		}
		isLoading = false;
	}

	async function resetForm() {
		clientBody = new ClientNewModel();
		prospect = false;
	}

	async function communeQuery(codePostal){
		return await getCommunesFromCodePostal(codePostal).then((res) => {
			if(res.data.length === 0){
				return [{value: '', label: ''}];
			}
			return res.data.map((commune) => ({
				value: commune.LibelleAcheminement,
				label: commune.NomCommune
			}));
		});
	}
</script>

<div class="text-card-foreground pb-4">
	<div class="flex flex-col space-y-1.5 p-6">
		<h1 class="whitespace-nowrap text-2xl font-semibold leading-none tracking-tight">
			Créer un client
		</h1>
	</div>

	<!-- Wrap everything in a form -->
	<form class="p-6 space-y-4" on:submit={createClient}>
		<FormGroup title="Identification" isCollapsible={false}>
			<div class="flex gap-2">
				<Select id="gender"
								label="Genre"
								width="w-1/5"
								options={genderOptions}
								bind:value={clientBody.Qualite}
								required />
				<Input
					id="name"
					placeholder="Nom"
					label="Nom"
					bind:value={name}
					required
				/>

				<Input
					id="surname"
					placeholder="Prénoms"
					label="Prénoms"
					bind:value={surname}
				/>
			</div>
		</FormGroup>

		<FormGroup title="Coordonnées Fiscales" isCollapsible={false}>
			<div class="grid grid-cols-4 gap-2">
				<Input
					id="Adresse"
					placeholder="Adresse"
					label="Adresse"
					maxlength="35"
					bind:value={clientBody.Adresse}
				/>
				<Input
					id="Complement"
					placeholder="Complement"
					label="Complement"
					maxlength="35"
					bind:value={clientBody.Complement}
				/>
				<Input
					id="CodePostal"
					placeholder="CodePostal"
					label="CodePostal"
					maxlength="10"
					bind:value={clientBody.CodePostal}
					on:input={async (e) => coordonneesCommunes = await communeQuery(e.detail)}
				/>
				<Combobox
					id="Ville"
					label="Ville"
					placeholder="Ville"
					options={coordonneesCommunes}
					bind:value={clientBody.Ville}
					required
				/>
				<TelInput
				id="Telephone"
				placeholder="Telephone"
				label="Telephone"
				maxlength="10"
				bind:value={clientBody.Telephone}
			/>
				<TelInput
					id="Telephone URSSAF"
					placeholder="Téléphone URSSAF"
					label="Téléphone URSSAF"
					maxlength="10"
					bind:value={clientBody.Telecopie}
				/>
				<MailInput
					id="Mail"
					placeholder="Mail"
					label="Mail"
					maxlength="69"
					bind:value={clientBody.Mail}
				/>
			</div>
		</FormGroup>

		<FormGroup title="Coordonnées Secondaire" isOpen={false}>
			<div class="grid grid-cols-4 gap-2">
				<Input
					id="AdresseSecondaire"
					placeholder="Adresse"
					label="Adresse"
					maxlength="35"
					bind:value={secondaryAddress.Adresse}
				/>
				<Input
					id="ComplementSecondaire"
					placeholder="Complement"
					label="Complement"
					maxlength="35"
					bind:value={secondaryAddress.Complement}
				/>
				<Input
					id="CodePostalSecondaire"
					placeholder="CodePostal"
					label="CodePostal"
					maxlength="10"
					bind:value={secondaryAddress.CodePostal}
					on:input={async (e) => secondaryCoordonneesCommunes = await communeQuery(e.detail)}
				/>
				<Combobox
					id="VilleSecondaire"
					label="Ville"
					placeholder="Ville"
					options={secondaryCoordonneesCommunes}
					bind:value={secondaryAddress.Ville}
					required
				/>
				<TelInput
					id="TelephoneSecondaire"
					placeholder="Telephone"
					label="Telephone"
					maxlength="11"
					bind:value={secondaryAddress.Telephone}
				/>
				<TelInput
					id="Telephone2Secondaire"
					placeholder="Téléphone 2"
					label="Téléphone 2"
					maxlength="11"
					bind:value={secondaryAddress.Telecopie}
				/>
				<MailInput
					id="MailSecondaire"
					placeholder="Mail"
					label="Mail"
					maxlength="69"
					bind:value={secondaryAddress.Email}
				/>
			</div>
		</FormGroup>

		<FormGroup title="Contact de confiance" isOpen={false}>
			<div class="grid grid-cols-3 gap-2">
				<Select
					id="civilite"
					label="Civilité"
					options={civiliteOptions}
					bind:value={contact.Civilite}
				/>
				<Input
					id="NomContact"
					placeholder="Nom"
					label="Nom"
					maxlength="35"
					bind:value={contact.Nom}
				/>

				<Input
					id="PrenomContact"
					placeholder="Prénom"
					label="Prénom"
					maxlength="35"
					bind:value={contact.Prenom}
				/>

				<MailInput
					id="MailContact"
					placeholder="Mail"
					label="Mail"
					maxlength="69"
					bind:value={contact.Email}
				/>

				<TelInput
					id="TelephoneContact"
					placeholder="Telephone"
					label="Telephone"
					maxlength="11"
					bind:value={contact.Telephone}
				/>

				<TelInput
					id="TelephonePortableContact"
					placeholder="Telephone Portable"
					label="Telephone Portable"
					maxlength="11"
					bind:value={contact.Portable}
				/>

				<Input
					id="Lien"
					placeholder="Lien avec le client"
					label="Lien"
					maxlength="11"
					bind:value={contact.Lien}
				/>
				<div></div>
			</div>
		</FormGroup>
		<FormGroup title="Informations complémentaires" isCollapsible={false}>
			<div class="flex gap-2">
				<CheckBox id="ursaffapi"
									label="API Urssaf"
									bind:checked={urssaffapi} />
			</div>
		</FormGroup>
		<div class="flex justify-center col-span-3">
			<ButtonPrimary py="py-2" px="px-5" type="submit" text="" isLoading={isLoading} disabled={disabled}>Créer</ButtonPrimary>
		</div>
	</form>
</div>
