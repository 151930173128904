<script>
    import { fade, scale } from 'svelte/transition';
    import { createEventDispatcher } from 'svelte';

    const dispatch = createEventDispatcher();

    export let show = false;
    export let title = '';

    // Ferme la modale quand on clique sur l'overlay ou la croix
    function closeModal() {
        dispatch('close');
    }

    // Empêche la propagation du clic sur le contenu de la modale
    function stopPropagation(event) {
        event.stopPropagation();
    }
</script>

{#if show}
    <!-- svelte-ignore a11y-no-noninteractive-element-interactions -->
    <div 
        role="dialog"
         class="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4"
        on:click={closeModal}
        on:keydown={e => e.key === 'Escape' && closeModal()}
        transition:fade
    >
        <!-- svelte-ignore a11y-no-noninteractive-element-interactions -->
        <div 
            role="document"
            class="bg-white rounded-lg shadow-xl w-full max-w-md"
            on:click={stopPropagation}
            on:keydown={stopPropagation}
            transition:scale={{duration: 200}}
        >
            <div class="flex justify-between items-center p-4 border-b">
                <h2 class="text-xl font-semibold">{title}</h2>
                <button 
                    class="text-gray-500 hover:text-gray-700 transition-colors"
                    on:click={closeModal}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                </button>
            </div>
            
            <div class="modal-content">
                <slot></slot>
            </div>
        </div>
    </div>
{/if}

<style>
    /* Assure que la modale est toujours au-dessus des autres éléments */
    :global(body:has(.modal-content)) {
        overflow: hidden;
    }
</style> 