<script>
	import { onMount } from 'svelte';
	import { get } from '../API/Backend/api';
	import Card from '../components/Card.svelte';
	import ButtonPrimary from '../components/Button/ButtonPrimary.svelte';

	onMount(() => {
		// Fetch the data from the backend
		get('/users-rights/2')
			.then((data) => {
				console.log(data);
			})
			.catch((error) => {
				console.log(error);
			});
	});
</script>

<div class="flex">
	<Card title="Coordonnées" classes="w-1/3 m-5">
		<div>
			<p><strong>ADLCOOP</strong></p>
			<p>Zone Artisanale de Bel Air</p>
			<p>87700 Saint Martin le Vieux</p>
		</div>
		<div class="mt-2">
			<p><strong>Téléphone: </strong><a href="tel:+33628045203"> 06 28 04 52 03 </a></p>
			<p><strong>Mail: </strong><a href="mailto:contact@adlcoop.fr">contact@adlcoop.fr</a></p>
		</div>
	</Card>

	<Card title="RIB" classes="w-1/3 m-5">
		<div>
			<p><strong>IBAN: </strong> FR76 1950 6000 1128 1309 3314 004</p>
			<p><strong>BIC: </strong> AGRIFRPP895</p>
		</div>
	</Card>

	<Card title="Liens utile" classes="w-1/3 m-5">
		<div>
			<div class="m-3">
				<ButtonPrimary text="text-lg">
					<a class="p-3" href="./CGP.pdf" download> CGP </a>
				</ButtonPrimary>
			</div>
			<div class="m-3">
				<ButtonPrimary text="text-lg">
					<a class="p-3" href="./RIB.pdf" download> RIB </a>
				</ButtonPrimary>
			</div>
			<div class="m-3">
				<ButtonPrimary text="text-lg">
					<a class="p-3" href="./FormulaireURSSAF.pdf" download> URSSAF </a>
				</ButtonPrimary>
			</div>
		</div>

	</Card>

</div>
