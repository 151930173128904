<script>
    import { createEventDispatcher } from 'svelte';

    export let id = '';
    export let label = '';
    export let value = '';
    export let placeholder = '';
    export let required = false;
    export let disabled = false;
    export let hidden = false;
    export let minWidth = 50;
    export let minlength = 0;
    export let maxlength = 255;
    export let pattern;
    export let timing = 500;
    export let classes = '';
    export let showDescription = true;
    export let description = '';

    let inputHtml;
    let typingTimer;

    const handleInput = (event) => {
      value = event.target.value;

      clearTimeout(typingTimer);

      typingTimer = setTimeout(() => {
        dispatch('input', value);
      }, timing);
    };

    const handleFocusOut = () => {
      dispatch('focusout');
    };

    export function checkWidthGreaterThanMin() {
      return inputHtml.clientWidth > minWidth;
    }

    export function focus() {
      if (inputHtml) {
        inputHtml.focus();
      }
      if (inputHtml !== window.document.activeElement) {
        setTimeout(() => {
          focus();
        }, 10);
      }
    }

    const dispatch = createEventDispatcher();
  </script>
  
  <div class="w-full" hidden={hidden}>
    {#if label}
      <label class="font-medium" for={id}>
        {label}
      </label>
    {/if}
    <input
      id={id}
      type='text'
      class="cursor-default w-full rounded-md border-none bg-{disabled ? 'gray-200' : 'white'} py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-primary sm:text-sm sm:leading-6 {classes}"
      bind:value
      bind:this={inputHtml}
      placeholder={placeholder}
      required={required}
      minlength={minlength}
      maxlength={maxlength}
      on:input={handleInput}
      on:focusout={handleFocusOut}
      pattern={pattern}
      disabled={disabled}
    />
    {#if description && showDescription}
      <p class="text-sm text-gray-500">{description}</p>
    {/if}
  </div>
  
  <style>
    label {
      display: block;
    }
  </style>
  