<script>
	// Components
	import DateInput from '../../Input/DateInput.svelte';
	import CheckBox from '../../Input/CheckBox.svelte';
	import Input from '../../Input/Input.svelte';
	import ButtonPrimary from '../../Button/ButtonPrimary.svelte';

	// Queries
	import { duplicateAvoir, duplicateDevis, duplicateFacture } from '../../../API/API_SAGE/DocVenteQueries.js';

	// Store
	import { addToast, closeDrawer } from '../../../store.js';

	export let data;
	export let selectedDocVente;

	let updateTarifs = false;
	let updateTauxTaxes = false;
	let updateInfosTiers = false;

	async function duplicate() {
		const body = {
			DocumentVenteOriginalNumPiece: data.originalDocPiece,
			DocumentVenteDuplicateNumPiece: data.duplicatedDocPiece.trim().toUpperCase(),
			DocumentVenteDuplicateCtNum: data.duplicatedDocClient,
			DocumentVenteDuplicateDate: data.duplicatedDocDate,
			DocumentVenteDuplicateDesign: data.duplicatedDocRef,
			UpdateTarif: updateTarifs,
			UpdateTaxe: updateTauxTaxes,
			UpdateTiers: updateInfosTiers
		}

		let response;
		switch (selectedDocVente) {
			case 'devis':
				response = await duplicateDevis(body).then((res) => res.data);
				break;
			case 'facture':
				response = await duplicateFacture(body).then((res) => res.data);
				break;
			case 'avoir':
				response = await duplicateAvoir(body).then((res) => res.data);
				break;
		}

		window.dispatchEvent(new CustomEvent('refresh'));
		closeDrawer();
		if (response.status !== 200) {
			addToast(response.detail, 'bg-red-500');
		} else {
			addToast('Duplication effectuée avec succès', 'bg-green-500');
		}
	}
</script>

<Input
	label="N° Piece Originel"
	bind:value={data.originalDocPiece}
	disabled />

<div class="grid grid-cols-2 gap-4 justify-between mt-4">
	<Input label="Nouveau N° Piece" bind:value={data.duplicatedDocPiece} classes="mt-2" />
	<Input label="Nouveau Client" bind:value={data.duplicatedDocClient} classes="mt-2"/>
	<DateInput label="Nouvelle date" bind:value={data.duplicatedDocDate} />
	<Input label="Nouvelle référence" bind:value={data.duplicatedDocRef} classes="mt-2" />
</div>

<div class="flex-col">
	<CheckBox label="Mettre à jour les tarifs" bind:checked={updateTarifs} />
	<CheckBox label="Mettre à jour les taux de taxes" bind:checked={updateTauxTaxes} />
	<CheckBox label="Reprendre les informations du tiers" bind:checked={updateInfosTiers} />
</div>

<div class=" w-full flex justify-center mt-2">
	<ButtonPrimary on:click={duplicate}>
		<div class="p-2 font-medium text-sm">
			Dupliquer
		</div>
	</ButtonPrimary>
</div>