<script>
	// Components
	import DataTable from '../../components/DataTable.svelte';
	import Select from '../../components/Select/Select.svelte';
	import Card from '../../components/Card.svelte';
	import Drawer from '../../components/Drawer.svelte';
	import DuplicateDocumentForm from '../../components/Form/DocumentVente/DuplicateDocumentForm.svelte';
	import TransformDocumentVenteForm from '../../components/Form/DocumentVente/TransformDocumentVenteForm.svelte';
	import LinkAction from '../../components/Actions/LinkAction.svelte';

	// API
	import {
		getDevis,
		getFacture,
		getAvoir,
		deleteDevis,
		deleteFacture,
		deleteAvoir
	} from '../../API/API_SAGE/DocVenteQueries.js';
	import { getCA } from '../../API/API_SAGE/SocietyQueries.js';

	// Store
	import { openDrawer, addToast } from '../../store.js';
	import { formatDateForDisplay, formatToCurrency, printVentes } from '../../utils.js';
	import { connectedUserHasAccess, getConnectedUser } from '../../API/Backend/api.js';
	import { getCollaborateurByNum } from '../../API/API_SAGE/CollaborateurQueries.js';
	import { getClientByReference } from '../../API/API_SAGE/ClientQueries.js';
	import { onMount } from 'svelte';
	import { List } from '../../API/Backend/rights/permissions.js';

	const unselectedColumns = ['DO_Piece', 'DO_Ref', 'DO_Date'];

	const columns = [
		{ key: 'Urssaf', title: '', type: 'indicator', action: actionAPIIndicator, canSearch: false, canSort: false },
		{ key: 'DO_Piece', title: 'N° Piece' },
		{ key: 'Client.CT_Intitule', title: 'Intitule Client', canSort: false, canSearch: false },
		{ key: 'DO_Ref', title: 'Référence' },
		{ key: 'DO_Date', title: 'Date du document' },
		{ key: 'DO_TotalHT', title: 'Montant HT', canSort: false, canSearch: false },
		{
			key: '',
			title: 'Montant COOP',
			display: actionCalculMontantCoop,
			canSort: false,
			canSearch: false
		},
		{ key: 'DO_TotalTTC', title: 'Montant TTC' },
	];

	const docLignesColumns = [
		{ key: 'Article.AR_Ref', title: 'Référence article' },
		{ key: 'DL_Design', title: 'Désignation' },
		{ key: 'DL_Qte', title: 'Quantité' },
		{ key: 'Conditionnement.EU_Enumere', title: 'Conditionnement' },
		{ key: 'DatePrestation.Valeur', title: 'Date de prestation' },
		{ key: 'DL_PrixUnitaire', title: 'PU HT' },
		{ key: 'DL_PUTTC', title: 'PU Net' }
	];

	const docVenteOptions = [
		{ value: 'devis', label: 'Devis' },
		{ value: 'facture', label: 'Facture' },
		{ value: 'avoir', label: 'Avoir' }
	];
	const transformationCorrespondance = {
		devis: 'FA',
		facture: 'FV'
	};
	const baseActions = [
		{ component: LinkAction, props: { content: 'Modifier', action: 'modifier' } },
		{ component: LinkAction, props: { content: 'Dupliquer', action: 'duplicate' } },
		{ component: LinkAction, props: { content: 'Imprimer', action: 'print' } },
	];

	let actions = [...baseActions];
	let columnsDatatable = columns;
	let selectedDocVente = sessionStorage.getItem('selectedDocVente') ?? 'devis';
	let totalHT = 0;
	let totalRegle = 0;
	let totalHTCOOP = 0;

	onMount(async () => {
		connectedUserHasAccess('document-vente', List);
	});

	function handleClick(message) {
		alert(message);
	}

	function handleRowClick(event) {
		const rowData = event.detail;
		if(rowData.DocLignes && rowData.DocLignes.length > 0) {
			for (const ligne of rowData.DocLignes) {
				if(ligne.DL_PieceDE) {
					rowData.PieceDevis = ligne.DL_PieceDE;
				}
			}
		}
		openDrawer(rowData);
	}

	async function handleDuplication(event) {
		const rowData = event.detail;
		let originalDocPiece = rowData.DO_Piece;
		let duplicatedDocPiece = '';
		let duplicatedDocRef = rowData.DO_Ref;
		let duplicatedDocClient = rowData.Client.CT_Num;
		let duplicatedDocDate = new Date().toISOString().split('T')[0];
		openDrawer({
			id: 'duplication',
			data: {
				originalDocPiece,
				duplicatedDocPiece,
				duplicatedDocRef,
				duplicatedDocClient,
				duplicatedDocDate
			}
		});
	}

	function handleTransformation(event) {
		let typeDocDest = transformationCorrespondance[selectedDocVente];
		openDrawer({ id: 'transformation', data: { ...event.detail, typeDocDest: typeDocDest } });
	}

	async function handleDocVente(
		strictSearchFields,
		strictSearchValues,
		excludeSearchFields,
		excludeSearchValues,
		searchFields,
		searchValues,
		OrderDirection,
		pageNumber,
		pageSize,
		OrderBy
	) {
		let response;
		let user = await getConnectedUser();
		let matricule = '';
		if (user.sageLink) {
			matricule = await getCollaborateurByNum(user.sageLink).then((res) => res.data.CO_Matricule);

			if (!matricule) {
				addToast('Aucun document disponible !', 'bg-red-500');
				return [];
			}

			strictSearchFields += 'CT_NumPayeur';
			strictSearchValues += matricule;
		}
		switch (selectedDocVente) {
			case 'devis': {
				let personalizedActions = [
					{
						component: LinkAction,
						props: { content: 'Transformer en facture', action: 'transformation' }
					},
					{ component: LinkAction, props: { content: 'Supprimer', action: 'delete' } }

				];
				actions = [...baseActions, ...personalizedActions];
				columnsDatatable = columns;
				response = await getDevis(
					strictSearchFields,
					strictSearchValues,
					excludeSearchFields,
					excludeSearchValues,
					searchFields,
					searchValues,
					OrderDirection,
					pageNumber,
					pageSize,
					OrderBy
				);

				await handleGetCA(0, matricule);
				break;
			}
			case 'facture':
				actions = baseActions;
				columnsDatatable = [
					{ key: 'DO_Valide', title: 'Validé', type: 'lock', action: (row) => row.DO_Valide },
					...columns,
					{
						key: 'Reglement',
						title: '',
						type: 'indicator',
						action: actionPaymentIndicator,
						canSort: false,
						canSearch: false
					},
					{ key: 'DO_MontantRegle', title: 'Montant réglé', canSort: false, canSearch: false },
				];
				response = await getFacture(
					strictSearchFields,
					strictSearchValues,
					excludeSearchFields,
					excludeSearchValues,
					searchFields,
					searchValues,
					OrderDirection,
					pageNumber,
					pageSize,
					OrderBy
				).then(res => {
					for (let docData of res.data) {
						if (docData.Reglement) {
							for (let reglement of docData.Reglement) {
								docData.DO_MontantRegle += reglement.DR_Montant;
							}
						}
					}
					return res;
				});

				await handleGetCA(6, matricule);
				break;
			case 'avoir':
				actions = baseActions;
				columnsDatatable = columns;
				response = await getAvoir(
					strictSearchFields,
					strictSearchValues,
					excludeSearchFields,
					excludeSearchValues,
					searchFields,
					searchValues,
					OrderDirection,
					pageNumber,
					pageSize,
					OrderBy
				);
				totalHT = 0;
				totalRegle = 0;
				totalHTCOOP = 0;
				break;
			default:
				response = [];
				break;
		}

		if (Array.isArray(response.data)) {
			for (let itemData of response.data) {
				itemData.DO_Date = formatDateForDisplay(itemData.DO_Date);

				if (itemData.DocLignes) {
					for (let ligne of itemData.DocLignes) {
						ligne.DatePrestation = ligne.InfoLibres.filter(
							(res) => res.Intitule === 'LS_DATE_PRESTATION'
						)[0];
						if (ligne.DatePrestation && ligne.DatePrestation.Valeur) {
							ligne.DatePrestation.Valeur = formatDateForDisplay(ligne.DatePrestation.Valeur);
						}
					}
					itemData.DocLignes.sort((a, b) => a.DL_Ligne - b.DL_Ligne);
				}

				itemData.Client = await getClientByReference(itemData.Client.CT_Num).then(
					(res) => res.data
				);
			}
		}
		return response;
	}

	async function handleGetCA(docType, matricule) {
		await getCA(docType, matricule).then((res) => {
			totalHT = res.data.TotalHT ?? 0	;
			totalRegle = res.data.MontantRegle ?? 0;
			totalHTCOOP = totalHT * 0.95;
		});
	}

	async function handleDelete(event) {
		let confirmation = confirm(`Vous allez supprimer ${event.detail.DO_Piece}`);
		let result;
		if (!confirmation) {
			return;
		}

		switch (selectedDocVente) {
			case 'devis':
				result = await deleteDevis(event.detail.DO_Piece).then((res) => res.data);
				break;
			case 'facture':
				result = await deleteFacture(event.detail.DO_Piece).then((res) => res.data);
				break;
			case 'avoir':
				result = await deleteAvoir(event.detail.DO_Piece).then((res) => res.data);
				break;
			default:
				addToast('Erreur lors de la suppression !', 'bg-red-500');
				break;
		}

		if (result.statusCode && result.statusCode !== 200) {
			addToast(`Erreur lors de la suppression ! ${result.value.detail}`, 'bg-red-500');
			return;
		}

		addToast(result.value.detail, 'bg-green-500');
		window.dispatchEvent(new CustomEvent('refresh'));
	}

	function actionPaymentIndicator(row) {
		if (row.DO_TotalTTC - row.DO_MontantRegle === 0) {
			return 'bg-green-500';
		} else if (row.DO_MontantRegle === 0) {
			return 'bg-red-500';
		} else {
			return 'bg-orange-300';
		}
	}

	function actionAPIIndicator(row) {
		let apiUrssaf = row.InfoLibres.find(res => res.Intitule === "LS_API");
		if (apiUrssaf) {
			switch(apiUrssaf.Valeur) {
				case "2": return 'bg-green-500';  // En attente de validation
				case "1": return 'bg-yellow-300';  // En cours d'inscription
				case "0": return 'bg-transparent'; // Non inscrit
				default: return 'bg-transparent';
			}
		}
	}

	function actionCalculMontantCoop(row) {
		let mtnCoop = (row.DO_TotalHT * 0.95).toFixed(2);

		return mtnCoop == 0 ? '' : mtnCoop;
	}

	function handleModifier(event) {
		if (event.detail.DO_Valide) {
			addToast('Les factures validées ne peuvent plus être modifiées !', 'bg-red-500');
			return;
		}
		window.open(`/ventes/${selectedDocVente}/${event.detail.DO_Piece}`, '_self');
	}

</script>

<div class="grid grid-cols-2 gap-4 m-2">
	<div class="space-y-2">
		<Select
			id="docType"
			label="Type de document"
			options={docVenteOptions}
			bind:value={selectedDocVente}
			on:change={() => {
				sessionStorage.setItem('selectedDocVente', selectedDocVente);
				window.dispatchEvent(new CustomEvent('refresh'));
			}}
			width="w-1/3"
		/>
	</div>
	<Card title="Chiffre d'affaires">
		<div class="space-y-2">
			<h3 class="whitespace-nowrap text-lg leading-none tracking-tight">
				<span class="font-medium">Total Chiffre d'affaire HT</span>: {formatToCurrency(totalHT)}
			</h3>
			<h3 class="whitespace-nowrap text-lg leading-none tracking-tight">
				<span class="font-medium">Total Montant Réglé</span>: {formatToCurrency(totalRegle)}
			</h3>
			<h3 class="whitespace-nowrap text-lg leading-none tracking-tight">
				<span class="font-medium">Total HT COOP</span>: {formatToCurrency(totalHTCOOP)}
			</h3>
		</div>
	</Card>
</div>

{#key selectedDocVente}
<DataTable
	onClick={handleClick}
	getQuery={handleDocVente}
	columns={columnsDatatable}
	unselectedColumnsKeys={unselectedColumns}
	rowsPerPage={10}
	on:rowClick={handleRowClick}
	on:duplicate={handleDuplication}
	on:transformation={handleTransformation}
	on:modifier={handleModifier}
	on:delete={handleDelete}
	on:print={(event) => printVentes(event.detail)}
	{actions}
/>
{/key}

<Drawer title="Détails du document de vente" width="w-2/3" maxWidth="">
	<div slot="drawerContent" let:data>
		<!-- Drawer content -->
		{#if data.DO_Piece}
			<p>N° Piece: {data.DO_Piece}</p>
		{/if}
		{#if data.CT_Intitule}
			<p>Intitule Client: {data.Client.CT_Intitule}</p>
		{/if}
		{#if data.DO_Ref}
			<p>Référence: {data.DO_Ref}</p>
		{/if}
		{#if data.DO_Date}
			<p>Date du document: {data.DO_Date}</p>
		{/if}
		{#if data.DO_TotalHT}
			<p>Montant HT: {data.DO_TotalHT}</p>
		{/if}
		{#if data.DO_TotalTTC}
			<p>Montant TTC: {data.DO_TotalTTC}</p>
		{/if}
		{#if data.DO_MontantRegle}
			<p>Montant réglé: {data.DO_MontantRegle}</p>
		{/if}
		{#if data.PieceDevis}
			<p>Devis d'origine: {data.PieceDevis}</p>
		{/if}
		<!--						 Datatable with doc lines-->
		{#if data.DocLignes && data.DocLignes.length > 0}
			<h3 class="text-base font-semibold text-gray-700 mt-2">Lignes du document</h3>
			<hr class="mt-2" />
			<DataTable fromList={data.DocLignes} rowsPerPage={10} columns={docLignesColumns} />
		{/if}
	</div>
</Drawer>

<Drawer id="duplication" title="Dupliquer le {selectedDocVente}">
	<div slot="drawerContent" let:data>
		<DuplicateDocumentForm {data} {selectedDocVente} />
	</div>
</Drawer>

<Drawer id="transformation" title="Transformer le {selectedDocVente}">
	<div slot="drawerContent" let:data>
		<TransformDocumentVenteForm {data} {selectedDocVente} />
	</div>
</Drawer>
