<script>
	import ButtonPrimary from '../../Button/ButtonPrimary.svelte';
	import Input from '../../Input/Input.svelte';

	import { duplicateArticle } from '../../../API/API_SAGE/ArticleQueries.js';
	import { addToast } from '../../../store.js';
	import { createEventDispatcher } from 'svelte';

	const dispatch = createEventDispatcher();

	export let AR_Ref = '';
	let newAR_Ref = '';
	let newAR_Design = '';

	async function duplicateArticleSubmition(event) {
		event.preventDefault();

		try {
			let response = await duplicateArticle(AR_Ref, newAR_Ref.trim().toUpperCase(), newAR_Design);

			if (response.data.status == 400) {
				dispatch('closeDrawer');
				addToast(`Erreur lors de la duplication : ${response.data.detail}`, 'bg-red-500');
				return;
			}

			dispatch('closeDrawer');
			addToast('Duplication réussie', 'bg-green-500');
			window.dispatchEvent(new CustomEvent('refresh'));
		} catch (error) {
			dispatch('closeDrawer');
			console.error(error);
			addToast('Erreur lors de la duplication', 'bg-red-500');
		}
	}
</script>

<div class="text-card-foreground pb-4">
	<form class="p-6 space-y-4" on:submit={duplicateArticleSubmition}>
		<div class="grid grid-cols-2 gap-4">
			<div class="space-y-2">
				<Input
					id="ref"
					placeholder="Réf"
					label="Référence"
					bind:value={AR_Ref}
					required
					minlength="1"
					maxlength="18"
					disabled
				/>
			</div>
			<div class="space-y-2">
				<Input
					id="new-ref"
					placeholder="Nouvelle Réf"
					label="Nouvelle Référence"
					bind:value={newAR_Ref}
					required
					maxlength="18"
				/>
			</div>
			<div class="space-y-2">
				<Input
					id="new-designation"
					placeholder="Nouvelle Désignation"
					label="Nouvelle Désignation"
					bind:value={newAR_Design}
					required
					maxlength="69"
				/>
			</div>
		</div>


		<div class="flex items-center w-full justify-center">
			<ButtonPrimary px="px-6" py="py-2" text="text-lg" type="submit">Dupliquer</ButtonPrimary>
		</div>
	</form>
</div>
