

/**
 * @class Model
 * @description Model generated from schema
 * @property {string} Numero - 
 * @property {string} DteInter - 
 * @property {string} Prestation - 
 * @property {number} Qte - 
 * @property {string} Unite - 
 * @property {number} PUHT - 
 * @property {number} TotalHT - 
 */
class VentesDataEntryModel {
  /**
   * @constructor
   */
  constructor() {
    /** @type {string} */
    this.Numero = null;
    /** @type {string} */
    this.DteInter = null;
    /** @type {string} */
    this.Prestation = null;
    /** @type {number} */
    this.Qte = null;
    /** @type {string} */
    this.Unite = null;
    /** @type {number} */
    this.PUHT = null;
    /** @type {number} */
    this.TotalHT = null;
  }
}

export default VentesDataEntryModel;
