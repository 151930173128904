<script>
    // Component
    import Input from '../Input/Input.svelte';

    import { Icon, ChevronDown } from 'svelte-hero-icons';
    import { createEventDispatcher, onMount } from 'svelte';

    export let query;
    export let options = [{ label: '', value: '' }];
    export let value = '';
    export let label = 'Combobox';
    export let placeholder = 'Search...';
    export let width = 'w-full'; // Default width
    let isOpen = false;
    let isLoading = false;
    let isSearch = false;
    let searchTerm = ''; // Term used for filtering
    let filteredOptions = options;
    const dispatch = createEventDispatcher();

    // Get the selected option
    $: selectedOption = options.find((option) => option.value === value) || options[0];

    // Filter options based on the search term
    $: filteredOptions = options.filter((option) =>
        option.label.toLowerCase().includes(searchTerm.toLowerCase())
    );

    // Toggle the dropdown visibility
    function toggleDropdown() {
        isOpen = !isOpen;
    }

    // Handle selecting an option
    function selectOption(option) {
        value = option.value;
        searchTerm = option.label;
        isSearch = false;
        isOpen = false;
				dispatch('change', value);
    }

    let comboboxElement;

    onMount(async () => {
        // Handle click outside
        const handleClickOutside = (event) => {
            if (comboboxElement && !comboboxElement.contains(event.target)) {
                isOpen = false;
            }
        };

        if (query) {
            isLoading = true;
            let result = await query().then((res) => {
                isLoading = false;
                return res;
            });
            options = [{ label: '', value: '' }, ...result];
        }
        document.addEventListener('click', handleClickOutside);
    });

    export async function reset() {
        value = '';
        searchTerm = '';
        isSearch = false;
        isOpen = false;

        if (query) {
            isLoading = true;
            let result = await query().then((res) => {
                isLoading = false;
                return res;
            });
            options = [{ label: '', value: '' }, ...result];
        }
    }

    async function handleInput(event) {
        isOpen = true;
        isSearch = true;
        searchTerm = event.detail;
        if (query) {
            isLoading = true;
            options = await query(searchTerm);
            isLoading = false;
        }
    }
</script>

<div class={width} bind:this={comboboxElement}>
    <label for="combobox" class="block text-sm font-medium leading-6 text-gray-900">{label}</label>
    <div class="relative mt-2">
        <!-- Input field shows the selected option's label but does not affect search -->
        <Input id="combobox"
               placeholder={placeholder + (isLoading ? ' en cours de chargement...' : '')}
               value={isSearch ? searchTerm : selectedOption.label}
               on:input={handleInput}
        />

        <!-- Button to toggle the dropdown -->
        <button type="button" class="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none" on:click={toggleDropdown}>
            <Icon src={ChevronDown} class="h-5 w-5 text-gray-400" />
        </button>

        <!-- Dropdown options -->
        {#if isOpen}
            <ul id="options" class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm" role="listbox">
                {#if filteredOptions.length === 0}
                    {#if isLoading}
                        <li class="relative cursor-default select-none py-2 pl-8 pr-4 text-gray-900">Chargement...</li>
                    {:else}
                        <li class="relative cursor-default select-none py-2 pl-8 pr-4 text-gray-900">Aucun résultat trouvé</li>
                    {/if}
                {/if}
                <!-- svelte-ignore a11y-click-events-have-key-events -->
                {#each filteredOptions as option}
                    <!-- svelte-ignore a11y-click-events-have-key-events -->
                    <!-- svelte-ignore a11y-no-noninteractive-element-interactions -->
                    <li
                        class="relative cursor-default select-none py-2 pl-8 pr-4 text-gray-900 hover:bg-primary hover:text-white"
                        on:click={() => selectOption(option)}
                    >
                        <span class="block truncate {option.value === value ? 'font-semibold' : 'font-normal'}">{option.label}</span>
                        {#if option.value === value}
                            <span class="absolute inset-y-0 left-0 flex items-center pl-1.5 text-primary">
                                <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                    <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd" />
                                </svg>
                            </span>
                        {/if}
                    </li>
                {/each}
            </ul>
        {/if}
    </div>
</div>
