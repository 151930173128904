<script>
	export let action = () => {};
	export let content = 'Action';
</script>

<div class="font-medium">
	<button type="button" on:click={action} class="text-primary hover:text-primaryDark p-1">
		{content}
	</button>
</div>
