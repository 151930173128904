<script>
	//Components
	import DataTable from '../../components/DataTable.svelte';
	import LinkAction from '../../components/Actions/LinkAction.svelte';
	import ButtonPrimary from '../../components/Button/ButtonPrimary.svelte';
	import AddRightToUser from '../../components/Form/Administration/AddRightToUser.svelte';
	import Drawer from '../../components/Drawer.svelte';
	import Select from '../../components/Select/Select.svelte';

	//Queries
	import { addRightToUser, getUsersRights, removeRightFromUser } from '../../API/Backend/rights/UserRightsQueries.js';

	//Svelte
	import { onMount } from 'svelte';

	//Utils
	import { connectedUserHasRight } from '../../API/Backend/api.js';
	import { openUrlInCurrentTab, ucfirst } from '../../utils.js';
	import { addToast, closeDrawer, openDrawer } from '../../store.js';
	import { All, allPermissions, Create, Delete, Edit, List } from '../../API/Backend/rights/permissions.js';

	const columns = [
		{ key: 'user.name', title: 'Utilisateur'},
		{ key: 'right.name', title: 'Droit', display: (row) => {
				return ucfirst(row.right.name).replace('_', ' ');
			}},
		{ key: 'right.description', title: 'Description'},
		{ key: 'permissions.data', title: 'Permission', display: (row) => {
				let permissions = row.permissions;
				if (permissions === All || permissions === allPermissions.reduce((acc, permission) => acc | permission.value, 0)) {
					return 'Tous';
				}
				let result = '';

				for (let permissionSearch of allPermissions) {
					if (permissions & permissionSearch.value) {
						if (result !== '') {
							result += ', ';
						}
						result += permissionSearch.name;
					}
				}
				return result;
			}},
	];
	const permissionsOptions = allPermissions.map(permission => ({ value: permission.value, label: permission.name }));

	let canList = false;
	let canCreate = false;
	let canEdit = false;
	let canDelete = false;
	let permission;
	let user;
	let right;
	let actions = [];

	onMount(async () => {
		canList = connectedUserHasRight('rights', List);
		if (!canList) {
			openUrlInCurrentTab('/acceuil');
		}
		canEdit = connectedUserHasRight('rights', Edit);
		if (canEdit) {
			actions.push({ component: LinkAction, props: { content: 'Editer', action: 'edit' } });
		}
		canDelete = connectedUserHasRight('rights', Delete);
		if (canDelete) {
			actions.push({ component: LinkAction, props: { content: 'Supprimer', action: 'delete' } });
		}
		canCreate = connectedUserHasRight('rights', Create);
	});

	async function getUserRights(strictSearchFields, strictSearchValues, excludeSearchFields, excludeSearchValues, searchFields, searchValues, OrderDirection, pageNumber, pageSize, OrderBy) {
		return await getUsersRights(strictSearchFields, strictSearchValues, excludeSearchFields, excludeSearchValues, searchFields, searchValues, OrderDirection, pageNumber, pageSize, OrderBy);
	}

	async function editRight() {
		permission = [permission].flat();
		let response = await addRightToUser(user, right, permission);
		if (response.statusCode && response.statusCode !== 200) {
			addToast(`Erreur lors de la modification du droit ! ${response.message}`, 'bg-red-500');
			return;
		}

		addToast('Droit modifié avec succès', 'bg-green-500');
		closeDrawer();
		window.dispatchEvent(new CustomEvent('refresh'));
	}

	function handleEdit(row) {
		user = row.detail.user.id;
		right = row.detail.right.id;
		permission = row.detail.permissions;
		permission = allPermissions.filter(perm => permission & perm.value).map(perm => perm.value);

		openDrawer({ id: 'edit', data: {
				users: [{ value: user, label: row.detail.user.name }],
				rights: [{ value: right, label: ucfirst(row.detail.right.name).replace('_', ' ') }],
			}
		});
	}

	async function handleDelete(row) {
		let response = await removeRightFromUser(row.detail.user.id, row.detail.right.id);
		if (response.statusCode && response.statusCode !== 200) {
			addToast(`Erreur lors de la suppression du droit ! ${response.message}`, 'bg-red-500');
			return;
		}

		addToast('Droit supprimé avec succès', 'bg-green-500');
		window.dispatchEvent(new CustomEvent('refresh'));
	}
</script>

<DataTable
	getQuery={getUserRights}
	{columns}
	rowsPerPage={10}
	{actions}
	on:edit={handleEdit}
	on:delete={handleDelete}
/>

<Drawer id="edit" title="Editer les droits">
	<div slot="drawerContent" let:data>
		<Select id="user"
						label="Utilisateur"
						options={data.users}
						value={user}
						disabled />

		<Select id="right"
							label="Droit"
							options={data.rights}
							value={right}
							disabled />

		<Select id="permission"
						label="Permission"
						multiple
						options={permissionsOptions}
						bind:value={permission} />

		<div class="py-2 w-full flex justify-center">
			<ButtonPrimary on:click={editRight}>
				<div class="m-1">
					Modifier les droits
				</div>
			</ButtonPrimary>
		</div>
	</div>
</Drawer>

{#if canCreate}
	<AddRightToUser />
{/if}
