<script>
	import NewDocVenteForm from '../../components/Form/DocumentVente/NewDocVenteForm.svelte';
	import { docVenteTypes } from '../../store.js';
	import { onMount } from 'svelte';
	import { connectedUserHasAccess } from '../../API/Backend/api.js';
	import { Create } from '../../API/Backend/rights/permissions.js';

	$: statutOptions = [
		{ value: 'Saisi', label: 'Saisi' },
		{ value: 'Confirme', label: 'Confirmé' },
		{ value: 'Accepte', label: 'Accepté' },
		{ value: 'Perdu', label: 'Perdu' },
		{ value: 'Archive', label: 'Archivé' }
	];

	onMount(() => {
		connectedUserHasAccess('devis', Create);
	});
</script>

<NewDocVenteForm docType={docVenteTypes.DEVIS} statutOptions={statutOptions} />

