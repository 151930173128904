<script>
	import { onMount } from 'svelte';
	import {
		getDevisByNumPiece,
		getFactureByNumPiece,
		getAvoirByNumPiece,
		updateDevisByNumPiece,
		updateFactureByNumPiece,
		updateAvoirByNumPiece
	} from '../../../API/API_SAGE/DocVenteQueries.js';
	import { getArticleByReference, getArticles } from '../../../API/API_SAGE/ArticleQueries.js';
	import { addToast } from '../../../store.js';
	import Input from '../../Input/Input.svelte';
	import DateInput from '../../Input/DateInput.svelte';
	import DoubleInput from '../../Input/DoubleInput.svelte';
	import ButtonPrimary from '../../Button/ButtonPrimary.svelte';
	import { getUniteVenteByReference } from '../../../API/API_SAGE/UniteVenteQueries.js';
	import { formatDateForInput, formatDateForDisplay, formatToCurrency, match, openUrlInCurrentTab, printVentes } from '../../../utils.js';
	import LoadingDots from '../../Loading/LoadingDots.svelte';
	import Combobox from '../../Combobox/Combobox.svelte';
	import CheckBox from '../../Input/CheckBox.svelte';
	import { calculateMontant } from '../../../API/API_SAGE/utils.js';
	import { getClientByReference, getClients } from '../../../API/API_SAGE/ClientQueries.js';
	import Select from '../../Select/Select.svelte';

	const currentPath = window.location.pathname;
	const docVenteType = currentPath.split('/')[2];
	const doPiece = currentPath.split('/')[3];
	let isLoading = true;
	let isComment = false;
	let docVente;
	let isModified = [];
	let prestationCombobox;
	let article;
	let client;
	let adressesLivraisonOptions = [];
	let adresseLivraison;

	// DOC LIGNES
	let DOCL_RefArticle = '';
	let DOCL_DesignArticle = '';
	let DOCL_PoidsBrut = 0.1;
	let DOCL_PoidsNet = 0.1;
	let DOCL_PU = 0.01;
	let DOCL_Qte = 1;
	let DOCL_uniteVente;
	let DOCL_minPU = 0.01;
	let DOCL_DatePrestation = formatDateForInput(new Date());

	// TOTAUX
	let totalHT = 0;
	let totalTTC = 0;

	onMount(async () => {
		switch (docVenteType) {
			case 'devis':
				docVente = await getDevisByNumPiece(doPiece).then(res => res.data);
				break;
			case 'facture':
				docVente = await getFactureByNumPiece(doPiece).then(res => res.data);
				break;
			case 'avoir':
				docVente = await getAvoirByNumPiece(doPiece).then(res => res.data);
				break;
			default:
				docVente = [];
				break;
		}
		if (docVente.DO_Valide === 1) {
			openUrlInCurrentTab("/ventes/liste-des-documents");
		}
		if (docVente.DocLignes === null) {
			docVente.DocLignes = [];
		}
		docVente.DocLignes.sort((a, b) => a.DL_Ligne - b.DL_Ligne);
		isLoading = false;
		client = await getClients("CT_NUM", docVente.Client.CT_Num, "", "", "", "", "").then((res) => res.data[0]);
		for (const adresse of client.AdressesLivraison) {
			adressesLivraisonOptions.push({
				value: adresse.LI_Intitule,
				label: `${adresse.LI_Adresse} - ${adresse.LI_CodePostal} ${adresse.LI_Ville}`
			});
		}
		adresseLivraison = adressesLivraisonOptions.find(adress => adress.value === docVente.Livraison.LI_Intitule).value;
		for (let i = 0; i < docVente.DocLignes.length; i++) {
			isModified[i] = { modification: false, comment: docVente.DocLignes[i].Article.AR_Ref === null };
			let article = await getArticleByReference(docVente.DocLignes[i].Article.AR_Ref).then((res) => res.data);
			let uniteVente = await getUniteVenteByReference(article.AR_UniteVen).then((res) => res.data);
			docVente.DocLignes[i].UniteVente = uniteVente.U_Intitule;
			if(docVente.DocLignes[i].DL_PieceDE) {
				docVente.PieceDevis = docVente.DocLignes[i].DL_PieceDE;
			}
			if (docVente.DocLignes[i].Article.AR_Ref) {
				article =
					article?.AR_Ref === docVente.DocLignes[i].Article.AR_Ref
						? article
						: await getArticleByReference(DOCL_RefArticle).then((res) => res.data);
				if (article.AR_PrixTTC === 1) {
					docVente.DocLignes[i].DL_PrixUnitaire = docVente.DocLignes[i].DL_PUTTC;
				}
				let [, , montantTTC, ] = calculateMontant(article, client, { Quantite: docVente.DocLignes[i].DL_Qte, PrixUnitaire: docVente.DocLignes[i].DL_PrixUnitaire, PrixTTC: article.AR_PrixTTC});
				docVente.DocLignes[i].DL_MontantTTC = montantTTC;
			}
			if (docVenteType === 'facture') {
				docVente.DocLignes[i].DatePrestation = docVente.DocLignes[i].InfoLibres.find(item => item.Intitule === "LS_DATE_PRESTATION")?.Valeur;
			}
		}
		totalHT = docVente.DocLignes.reduce(
			(acc, ligne) => acc + parseFloat(ligne.DL_MontantHT ?? 0),
			0
		);
		totalTTC = docVente.DocLignes.reduce(
			(acc, ligne) => acc + parseFloat(ligne.DL_MontantTTC ?? 0),
			0
		);
	});

	async function addDocLigne() {
		if (isComment) {
			docVente = {
				...docVente,
				DocLignes: [
					...docVente.DocLignes,
					{
						Article: {
							AR_Ref: null,
							AR_Design: null
						},
						DL_Design: DOCL_DesignArticle.trim(),
						DL_Qte: null,
						UniteVente: null,
						DL_PoidsBrut: null,
						DL_PoidsNet: null,
						DL_PrixUnitaire: null,
						DL_MontantHT: null
					}
				]
			};
			isModified.push({ modification: false, comment: true });
			return;
		}

		article =
			article?.AR_Ref === DOCL_RefArticle
				? article
				: await getArticleByReference(DOCL_RefArticle).then((res) => res.data);
		let [montantHt, , montantTTC, ] = calculateMontant(article, client, { Quantite: DOCL_Qte, PrixUnitaire: DOCL_PU, PrixTTC: article.AR_PrixTTC});

		docVente = {
			...docVente,
			DocLignes: [
				...docVente.DocLignes,
				{
					Article: {
						AR_Ref: DOCL_RefArticle.trim(),
						AR_Design: DOCL_DesignArticle.trim()
					},
					DL_Design: DOCL_DesignArticle.trim(),
					DL_Qte: DOCL_Qte,
					UniteVente: DOCL_uniteVente,
					DL_PoidsBrut: DOCL_PoidsBrut,
					DL_PoidsNet: DOCL_PoidsNet,
					DL_PrixUnitaire: DOCL_PU,
					DL_MontantHT: montantHt,
					DL_MontantTTC: montantTTC,
					DatePrestation: DOCL_DatePrestation,
				}
			]
		};
		isModified.push({ modification: false, comment: false });
		totalHT = Number(totalHT) + montantHt;
		totalTTC = Number(totalTTC) + montantTTC;

		DOCL_RefArticle = '';
		DOCL_DesignArticle = '';
		DOCL_PoidsBrut = 0.1;
		DOCL_PoidsNet = 0.1;
		DOCL_PU = 0.01;
		DOCL_Qte = 1;
		DOCL_uniteVente = "";
		DOCL_DatePrestation = formatDateForInput(new Date());
		prestationCombobox.reset();
	}

	async function updateDocLigne(index){
		isModified[index]['modification'] = false;
		let [montantHt, , montantTTC, ] = calculateMontant(article, client, { Quantite: docVente.DocLignes[index].DL_Qte, PrixUnitaire: article.AR_PrixVen, PrixTTC: article.AR_PrixTTC});
		docVente.DocLignes[index].DL_MontantHT = montantHt;
		docVente.DocLignes[index].DL_MontantTTC = montantTTC;
		totalHT = docVente.DocLignes.reduce(
			(acc, ligne) => acc + parseFloat(ligne.DL_MontantHT ?? 0),
			0
		);
		totalTTC = docVente.DocLignes.reduce(
			(acc, ligne) => acc + parseFloat(ligne.DL_MontantTTC ?? 0),
			0
		);
	}

	async function removeDocLigne(index) {
		totalHT = Number(totalHT);
		totalHT -= docVente.DocLignes[index].DL_MontantHT;
		totalHT = totalHT.toFixed(2);
		docVente = {
			...docVente,
			DocLignes: docVente.DocLignes.filter((_, i) => i !== index)
		};
	}

	async function saveDocVente() {
		const body = createBody();
		try {
			let response;
			switch (docVenteType) {
				case 'devis':
					response = await updateDevisByNumPiece(docVente.DO_Piece, body);
					break;
				case 'facture':
					response = await updateFactureByNumPiece(docVente.DO_Piece, body);
					break;
				case 'avoir':
					response = await updateAvoirByNumPiece(docVente.DO_Piece, body);
					break;
				default:
					response = {};
					addToast(`Erreur lors de l'envoi du document de vente.`, 'bg-red-500');
			}

			if (response.data.status === 400) {
				addToast(`Erreur lors de l'enregistrement du document de vente. ${response.data.detail}`, 'bg-red-500');
				return;
			}
			addToast(response.data.detail, 'bg-green-500');
		} catch (error) {
			addToast(`Erreur innatendue lors de la modification du document de vente. ${error}`, 'bg-red-500');
		}
	}

	function createBody() {
		let statut = match(docVenteType, {
			'devis': 'accepte',
			'facture': 'aCompta',
			'avoir': 'aFacturer',
		});
		let bufferAdresse = client.AdressesLivraison.find(adresse => adresse.LI_Intitule === adresseLivraison);
		let body = {
			Representant: {
				Nom: String(docVente.Collaborateur.CO_Nom),
				Prenom: String(docVente.Collaborateur.CO_Prenom)
			},
			Entete1: String(docVente.DO_Coord01),
			Entete2: String(docVente.DO_Coord02),
			Entete3: String(docVente.DO_Coord03),
			Entete4: String(docVente.DO_Coord04),
			Statut: String(statut),
			DocLignes: [],
			LivraisonAdresse: {
				LI_Intitule: bufferAdresse.LI_Intitule,
				Adresse:{
					Adresse: bufferAdresse.LI_Adresse,
					Complement: bufferAdresse.LI_Complement,
					CodePostal: bufferAdresse.LI_CodePostal,
					Ville: bufferAdresse.LI_Ville,
					Pays: bufferAdresse.LI_Pays
				}
			}
		};
		for (const index in docVente.DocLignes) {
			if (!docVente.DocLignes[index].Article.AR_Ref) {
				body.DocLignes.push({
					Num: Number(index) + 1,
					Designation: String(docVente.DocLignes[index].DL_Design),
				});
				continue;
			}

			body.DocLignes.push({
				Num: Number(index) + 1,
				Reference: String(docVente.DocLignes[index].Article.AR_Ref),
				Designation: String(docVente.DocLignes[index].DL_Design),
				PUHT: Number(docVente.DocLignes[index].DL_PrixUnitaire),
				PUTTC: Number(docVente.DocLignes[index].DL_PrixUnitaire),
				Qte: Number(docVente.DocLignes[index].DL_Qte),
				PUNet: Number(docVente.DocLignes[index].DL_PrixUnitaire),
				PoidsBrut: Number(docVente.DocLignes[index].DL_PoidsBrut),
				PoidsNet: Number(docVente.DocLignes[index].DL_PoidsNet),
				InfoLibres: [
					{
						Intitule: "LS_DATE_PRESTATION",
						Valeur: docVente.DocLignes[index].DatePrestation ?? new Date(docVente.DO_Date)
					}
				]
			});
		}
		return body;
	}

	async function articleQuery(searchTerm) {
		let data = await getArticles('', '', '', '', 'AR_Ref', searchTerm, 'ASC', 1, 1000, 'AR_Ref').then((res) => {
			return res.data.map((article) => ({
				value: article.AR_Ref,
				label: `${article.AR_Ref} | ${article.AR_Design}`
			}));
		});
		return data;
	}

</script>
<div class = "m-3">
	{#if isLoading}
		<LoadingDots />
	{/if}
	{#if docVente}
		<div class="text-card-foreground pb-4">
			<div class="flex flex-col space-y-1.5 p-6">
				<h1 class="whitespace-nowrap text-2xl font-semibold leading-none tracking-tight">
					Modifier un document
				</h1>
			</div>
				<div class="grid grid-cols-3 gap-4">
					<Input
						id="NumPiece"
						label="N° Piece"
						bind:value={docVente.DO_Piece}
						classes="mt-2"
						disabled=true
					/>
					<Input
						id="numClient"
						label="Client"
						bind:value={docVente.Client.CT_Intitule}
						classes="mt-2"
						disabled=true
					/>
					<DateInput
						id="date"
						label="Date"
						disabled = true
						bind:value={docVente.DO_Date} />
					{#if docVente.PieceDevis}
						<Input
							id="pieceDevis"
							label="Devis d'origine"
							bind:value={docVente.PieceDevis}
							classes="mt-2"
							disabled=true
						/>
					{/if}
				</div>
				<div class="grid grid-cols-3 gap-4">
					<Select
						id="adressesLivraison"
						label="Adresse de livraison"
						options={adressesLivraisonOptions}
						bind:value={adresseLivraison}
					/>
				</div>
				<div class="flex items-baseline justify-between">
					<h2 class="whitespace-nowrap text-xl font-semibold leading-none tracking-tight mt-6">
						Nouvelle ligne
					</h2>
					<hr class="flex-grow ml-4 border-t border-gray-300" />
				</div>
				<div class="grid grid-cols-2 gap-4">
					<div class="space-y-2 col-span-2 flex items-center space-x-3">
						{#if isComment}
							<Input
								label="Commentaire"
								bind:value={DOCL_DesignArticle}
								required
								maxlength={69}
							/>
						{:else}
							<Combobox
								id="ar_ref"
								label="Prestation"
								placeholder="Prestation"
								query={articleQuery}
								bind:this={prestationCombobox}
								bind:value={DOCL_RefArticle}
								on:change={async() => {
										article = await getArticleByReference(DOCL_RefArticle).then((res) => res.data);
										DOCL_DesignArticle = article.AR_Design ?? '';
										DOCL_PU = article.AR_PrixVen ?? '';
										DOCL_minPU = article.AR_PrixVen ?? '';
										let uniteVente = await getUniteVenteByReference(article.AR_UniteVen).then((res) => res.data ?? '');
										DOCL_uniteVente = uniteVente.U_Intitule;
									}}
								required
								minlength="3"
								maxlength="10"
							/>
						{/if}
						<div class="flex items-end h-full">
							<CheckBox id="isComment"
												label="Commentaire"
												bind:checked={isComment} />
						</div>
					</div>
					{#if !isComment}
						<div class="space-y-2">
							<DoubleInput
								id="qte"
								placeholder="1"
								label="Quantité"
								type="number"
								bind:value={DOCL_Qte}
							/>
						</div>

						<div class="space-y-2">
							<Input
								id="uniteVente"
								placeholder="Unité de vente"
								label="Unité de vente"
								bind:value={DOCL_uniteVente}
								disabled=true
							/>
						</div>

						<div class="space-y-2">
							<DoubleInput
								id="pu"
								placeholder="10.00"
								label="Prix unitaire"
								type="number"
								min={DOCL_minPU}
								bind:value={DOCL_PU}
							/>
						</div>
						{#if docVenteType === 'facture'}
							<div classs="space-y-2">
								<DateInput
									id="datePrestation"
									label="Date Prestation"
									bind:value={DOCL_DatePrestation}
								/>
							</div>
						{/if}
					{/if}

				</div>
				<div class="mt-2">
					<ButtonPrimary on:click={addDocLigne} type="button">Ajouter la ligne</ButtonPrimary>
				</div>
				<div class="mt-6">
					{#if docVente.DocLignes.length > 0}
						<table class="min-w-full divide-y divide-gray-200 border border-gray-200 rounded-lg">
							<thead class="bg-gray-50">
								<tr>
									<th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
										Référence
									</th>
									<th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
										Désignation
									</th>
									<th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
										Quantité
									</th>
									<th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
										Unité de vente
									</th>
									{#if docVenteType === 'facture'}
										<th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
											Date Prestation
										</th>
									{/if}
									<th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
										Prix Unitaire
									</th>
									<th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
										Montant HT
									</th>
									<th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
										Montant TTC
									</th>
									<th class="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
										Actions
									</th>
								</tr>
							</thead>
							<tbody class="bg-white divide-y divide-gray-200">
								{#each docVente.DocLignes as row, index}
									<tr>
										{#if !isModified[index]?.modification }
											<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
												{row.Article.AR_Ref ?? ''}
											</td>
											<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
												{row.DL_Design ?? ''}
											</td>
											<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
												{row.DL_Qte ?? ''}
											</td>
											<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
												{row.UniteVente ?? ''}
											</td>
											{#if docVenteType === 'facture'}
												<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
													{row.DatePrestation ? formatDateForDisplay(row.DatePrestation) : ''}
												</td>
											{/if}
											<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
												{row.DL_PrixUnitaire ? formatToCurrency(row.DL_PrixUnitaire) : ''}
											</td>
											<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
												{row.DL_MontantHT ? formatToCurrency(row.DL_MontantHT) : ''}
											</td>
											<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
												{row.DL_MontantTTC ? formatToCurrency(row.DL_MontantTTC) : ''}
											</td>
										{:else}
<!--											INPUT DOCLIGNE-->
											<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
												{row.Article.AR_Ref ?? ''}
											</td>
											<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
												{#if isModified[index]?.comment}
													<Input id="design"
																 bind:value={row.DL_Design}
													/>
												{:else}
													{row.DL_Design}
												{/if}
											</td>
											<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
												{#if !isModified[index]?.comment}
													<DoubleInput
														id="qte{index}"
														placeholder="1"
														label="Quantité"
														bind:value={row.DL_Qte}
													/>
												{/if}
											</td>
											<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
												{row.UniteVente ?? ''}
											</td>
											{#if docVenteType === 'facture'}
												<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
													{#if !isModified[index]?.comment}
														<DateInput
															id="datePrestation{index}"
															label="Date Prestation"
															bind:value={row.DatePrestation}
														/>
													{/if}
												</td>
											{/if}
											<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
												{#if !isModified[index]['comment']}
													<DoubleInput
														id="pu{index}"
														placeholder="10.00"
														label="Prix unitaire"
														min={isModified[index]['minPrice']}
														bind:value={row.DL_PrixUnitaire}
													/>
												{/if}
											</td>
											<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">

											</td>
											<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">

											</td>
										{/if}
										<td class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
											{#if !isModified[index]?.modification}
												<button
													class="text-blue-600 hover:text-blue-900"
													on:click={async () => {
														isModified[index]['modification'] = true;
														article = await getArticleByReference(row.Article.AR_Ref).then((res) => res.data);
														isModified[index]['minPrice'] = article.AR_PrixVen;
														docVente.DocLignes[index].DatePrestation = docVente.DocLignes[index].DatePrestation ? formatDateForInput(docVente.DocLignes[index].DatePrestation) : formatDateForInput(new Date());
													}}>Modifier</button
												>
											{:else}
												<button
													class="text-green-600 hover:text-green-900"
													on:click={() => updateDocLigne(index)}>Sauvegarder</button
												>
											{/if}
											<button
												class="text-red-600 hover:text-red-900"
												on:click={() => removeDocLigne(index)}>Supprimer</button
											>
										</td>
									</tr>
								{/each}
								<tr>
									<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
										<p class="font-semibold">Totaux</p>
									</td>
									<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">

									</td>
									<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">

									</td>
									<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">

									</td>
									<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">

									</td>

									{#if docVenteType === 'facture'}
										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">

										</td>
									{/if}

									<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
										{totalHT ? formatToCurrency(totalHT) : ''}
									</td>
									<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
										{totalTTC ? formatToCurrency(totalTTC) : ''}
									</td>
									<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">

									</td>
								</tr>
							</tbody>
						</table>
					{/if}
					<div class="flex items-center w-full justify-center mt-4 gap-5">
						{#if docVente.DocLignes.length > 0}
							<ButtonPrimary on:click={saveDocVente} px="px-6" py="py-2" text="text-lg">Sauvegarder</ButtonPrimary>
						{/if}
						<ButtonPrimary on:click={printVentes(docVente)} px="px-6" py="py-2" text="text-lg">Imprimer</ButtonPrimary>
					</div>
				</div>
		</div>
	{/if}
</div>