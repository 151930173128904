<script>
	import { Icon, ChevronDown, ChevronUp } from 'svelte-hero-icons';
	import { onMount } from 'svelte';
	import { connectedUserHasRight } from '../../API/Backend/api.js';

	export let title;
	export let items = [];
	export let icon;
	$: isOpen = false;

	onMount(() => {
		if (items.filter((item) => window.location.pathname.includes(item.href)).length > 0) {
			isOpen = true;
		}
	})

	function toggleGroup() {
		isOpen = !isOpen;
	}
</script>

<div class="group-container">
	<button class="flex items-center w-full p-3 text-gray-300 transition-colors duration-200 rounded-lg hover:bg-primaryDark hover:text-white" on:click={toggleGroup}>
		<Icon src={icon} class="w-6 h-6 mr-3" />
		<span class="text-sm font-medium">{title}</span>
		<Icon src={isOpen ? ChevronUp : ChevronDown} class="w-5 h-5 ml-auto" />
	</button>
	<div class="mt-2 space-y-2" class:hidden={!isOpen}>
		{#each items as item}
			{#if connectedUserHasRight(item.right, item.permission)}
				<a href={item.href} class="block px-4 py-2 text-sm {window.location.pathname.includes(item.href) ? 'text-primary' : 'text-gray-400'} transition-colors duration-200 rounded-md hover:bg-primary hover:text-white">
					{item.label}
				</a>
			{/if}
		{/each}
	</div>
</div>

<style>
	.group-container {
		margin-bottom: 1rem;
	}
</style>
