import { fetchData } from './utils.js';

/**
 * @param reference
 * @returns {Promise<{data: any}>}
 * @description Get Unité Vente by reference
 */
export async function getUniteVenteByReference(reference) {
	return await fetchData(`/uniteVente/${reference}`);
}

export async function getUniteVente() {
	return await fetchData(`/uniteVente/LUV`);
}