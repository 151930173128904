<script>
	import { Route, Router } from 'svelte-routing';
	import NotFound from 'views/404.svelte';
	import ManageRights from '../views/administration/ManageRights.svelte';
</script>

<Router url="administration">
	<Route path="manage-rights" component={ManageRights} />
	<Route path="create-user" component={NotFound} /> <!-- TODO -->
	<Route path="*" component={NotFound} />
</Router>