

/**
 * @class Model
 * @description Model generated from schema
 * @property {string} CT_Num - 
 * @property {string} CT_Intitule - 
 * @property {string} CG_NumPrinc - 
 */
class Fournisseur {
  /**
   * @constructor
   */
  constructor() {
    /** @type {string} */
    this.CT_Num = null;
    /** @type {string} */
    this.CT_Intitule = null;
    /** @type {string} */
    this.CG_NumPrinc = null;
  }
}

export default Fournisseur;
