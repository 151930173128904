<script>
    import { toasts, removeToast } from '../../store.js';
    import Toast from './Toast.svelte';
</script>

<div class="absolute z-[100]">
    {#each $toasts as { id, message, colorClass }}
        <Toast {id} {message} {colorClass} {removeToast} />
    {/each}
</div>
