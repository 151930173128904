<script>
	export let title = '';
	export let classes = '';
</script>

<div class="block rounded-lg bg-white shadow-secondary-1 shadow-md {classes}">
	<div
		class="border-b-2 border-neutral-100 px-6 py-3  font-medium">
		{title}
	</div>
	<div class="p-6">
		<slot></slot>
	</div>
</div>