<script>
import NewDocVenteForm from '../../components/Form/DocumentVente/NewDocVenteForm.svelte';
import { docVenteTypes } from '../../store.js';
import { onMount } from 'svelte';
import { connectedUserHasAccess } from '../../API/Backend/api.js';
import { Create } from '../../API/Backend/rights/permissions.js';

$: statutOptions = [
	{ value: 'Saisie', label: 'Saisie' },
	{ value: 'Confirme', label: 'Confirmé' },
	{ value: 'A comptabiliser', label: 'A comptabiliser' },
];

onMount(() => {
	connectedUserHasAccess('facture', Create);
});
</script>

<NewDocVenteForm docType="{docVenteTypes.FACTURE}" statutOptions="{statutOptions}" />