import VentesDataEntryModel from './VentesDataEntryModel';

/**
 * @class Model
 * @description Model generated from schema
 * @property {string} Adresse - 
 * @property {string} Dte - 
 * @property {string} NomSociete - 
 * @property {Array<VentesDataEntryModel>} DataSet - 
 * @property {string} NumDoc - 
 * @property {string} LogoPrincipal - 
 * @property {string} LogoSecondaire - 
 * @property {string} AdresseClt - 
 * @property {string} LogoSociete - 
 * @property {number} TotalHT - 
 * @property {number} TotalTVA10 - 
 * @property {number} TotalTVA20 - 
 * @property {number} TotalTTC - 
 * @property {string} Commentaire - 
 * @property {string} Nom_Client - 
 * @property {string} CL_Adresse_Liv - 
 * @property {string} BIC - 
 * @property {string} IBAN - 
 */
class FactureModel {
  /**
   * @constructor
   */
  constructor() {
    /** @type {string} */
    this.Adresse = null;
    /** @type {string} */
    this.Dte = null;
    /** @type {string} */
    this.NomSociete = null;
    /** @type {Array<VentesDataEntryModel>} */
    this.DataSet = [];
    /** @type {string} */
    this.NumDoc = null;
    /** @type {string} */
    this.LogoPrincipal = null;
    /** @type {string} */
    this.LogoSecondaire = null;
    /** @type {string} */
    this.AdresseClt = null;
    /** @type {string} */
    this.LogoSociete = null;
    /** @type {number} */
    this.TotalHT = null;
    /** @type {number} */
    this.TotalTVA10 = null;
    /** @type {number} */
    this.TotalTVA20 = null;
    /** @type {number} */
    this.TotalTTC = null;
    /** @type {string} */
    this.Commentaire = null;
    /** @type {string} */
    this.Nom_Client = null;
    /** @type {string} */
    this.CL_Adresse_Liv = null;
    /** @type {string} */
    this.BIC = null;
    /** @type {string} */
    this.IBAN = null;
  }
}

export default FactureModel;
