import ContactNewModel from './ContactNewModel';
import AdresseLivraisonModel from './AdresseLivraisonModel';
import ChampStatModel from './ChampStatModel';
import InfoLibreModel from './InfoLibreModel';

/**
 * @class Model
 * @description Model generated from schema
 * @property {string} CT_Num - 
 * @property {string} Intitule - 
 * @property {string} CompteCollectif - 
 * @property {string} Qualite - 
 * @property {string} Adresse - 
 * @property {string} Complement - 
 * @property {string} CodePostal - 
 * @property {string} Ville - 
 * @property {string} Region - 
 * @property {string} Pays - 
 * @property {string} Telephone - 
 * @property {string} Telecopie - 
 * @property {string} Mail - 
 * @property {string} Website - 
 * @property {string} Siret - 
 * @property {string} Naf - 
 * @property {string} Tva - 
 * @property {string} Commentaire - 
 * @property {string} CentralAchat - 
 * @property {string} TiersPayeur - 
 * @property {Array<ContactNewModel>} Contacts - 
 * @property {Array<AdresseLivraisonModel>} Adresses - 
 * @property {Array<ChampStatModel>} ChampsStats - 
 * @property {Array<InfoLibreModel>} InfoLibres - 
 */
class ClientNewModel {
  /**
   * @constructor
   */
  constructor() {
    /** @type {string} */
    this.CT_Num = null;
    /** @type {string} */
    this.Intitule = null;
    /** @type {string} */
    this.CompteCollectif = null;
    /** @type {string} */
    this.Qualite = null;
    /** @type {string} */
    this.Adresse = null;
    /** @type {string} */
    this.Complement = null;
    /** @type {string} */
    this.CodePostal = null;
    /** @type {string} */
    this.Ville = null;
    /** @type {string} */
    this.Region = null;
    /** @type {string} */
    this.Pays = null;
    /** @type {string} */
    this.Telephone = null;
    /** @type {string} */
    this.Telecopie = null;
    /** @type {string} */
    this.Mail = null;
    /** @type {string} */
    this.Website = null;
    /** @type {string} */
    this.Siret = null;
    /** @type {string} */
    this.Naf = null;
    /** @type {string} */
    this.Tva = null;
    /** @type {string} */
    this.Commentaire = null;
    /** @type {string} */
    this.CentralAchat = null;
    /** @type {string} */
    this.TiersPayeur = null;
    /** @type {Array<ContactNewModel>} */
    this.Contacts = [];
    /** @type {Array<AdresseLivraisonModel>} */
    this.Adresses = [];
    /** @type {Array<ChampStatModel>} */
    this.ChampsStats = [];
    /** @type {Array<InfoLibreModel>} */
    this.InfoLibres = [];
  }
}

export default ClientNewModel;
