import { writable } from 'svelte/store';

function createGlobalStore() {
    const { subscribe, set, update } = writable({
        initialData: null,
        isLoading: true,
        error: null
    });

    async function fetchInitialData() {
        try {
            const response = await fetch('https://api.example.com/initial-data');
            if (!response.ok) throw new Error('Erreur lors de la récupération des données');
            const data = await response.json();
            set({ initialData: data, isLoading: false, error: null });
        } catch (err) {
            set({ initialData: null, isLoading: false, error: err.message });
        }
    }

    return {
        subscribe,
        fetchInitialData
    };
}

export const globalStore = createGlobalStore();

// USER
export const isConnected = writable(false);

// Layout - Sidebar and Header
export const showLayout = writable(false);
const excludedRoutes = ['/auth'];

export function toggleLayout(pathname) {
	if (excludedRoutes.includes(pathname)) {
		showLayout.set(false);
	} else {
		showLayout.set(true);
	}
}

// SIDE BAR
export const sidebarOpen = writable(true);

// TOASTS
export const toasts = writable([]);

export function addToast(message, colorClass) {
	const id = Date.now();
	toasts.update((t) => [...t, { id, message, colorClass }]);
}

export function removeToast(id) {
	toasts.update((t) => t.filter((toast) => toast.id !== id));
}

// STORES
export const drawerState = writable({
    isOpen: false,
    data: null
});

export function openDrawer(data) {
	if (!('data' in data)) {
		data['data'] = data;
	}
	if (!('id' in data)) {
		data['id'] = 'drawer';
	}
	drawerState.set({ isOpen: true, ...data });
}

export function closeDrawer() {

    drawerState.set({ isOpen: false, data: null });
}


// UTILS
export function debounce(func, wait) {
	let timeout;
	return function(...args) {
		const later = () => {
			clearTimeout(timeout);
			func(...args);
		};
		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
	};
}

// Dictionnaire des types de documents de vente
export const docVenteTypes = {
	DEVIS: 'Devis',
	COMMANDE: 'Bon de Commande',
	PREPA_LIVRAISON: 'Préparation de livraison',
	BON_LIVRAISON: 'Bon de Livraison',
	BON_RETOUR: 'Bon de Retour',
	BON_AVOIR: "Bon d'avoir financier",
	FACTURE: 'Facture'
};

// Dictionnaire des types de comptabilité 
export const comptaTypes = {
	"Vente" : 0,
	"Achat" : 1,
	"Stock" : 2
}