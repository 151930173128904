import { generateDocVentes, generateStocksMouvement } from './API/API_SAGE/Report.js';
import StocksModel from './API/API_SAGE/model/StocksModel.js';
import StocksDataEntryModel from './API/API_SAGE/model/StocksDataEntryModel.js';
import FactureModel from './API/API_SAGE/model/FactureModel.js';
import DevisModel from '../src/API/API_SAGE/model/DevisModel.js';
import { getClientByReference } from './API/API_SAGE/ClientQueries.js';
import VentesDataEntryModel from './API/API_SAGE/model/VentesDataEntryModel.js';


/**
 * @description Transform a number into a currency format
 * @param {number} value
 * @param {string} currency
 * @returns {string}
 * @example
 * formatToCurrency(123456.789, 'EUR') // '123 456,79 €'
 */
export function formatToCurrency(value, currency = 'EUR') {
	return new Intl
		.NumberFormat(
			navigator.language,
			{
				style: 'currency',
				currency: currency
			}
		)
		.format(value);
}

/**
 * @description Open a URL in the current tab
 * @param {string} url
 * @return {void}
 */
export function openUrlInCurrentTab(url) {
	window.open(url, '_self');
}

/**
 * @description Open a URL in a new tab
 * @param {string} url
 * @return {void}
 */
export function openUrlInNewTab(url) {
	window.open(url, '_blank');
}

/**
 * @description Transform an array into chunks of a specific size
 * @param {[*]} array
 * @param {number} size
 * @returns {[*[]]}
 * @example
 * chunkArray([1, 2, 3, 4, 5], 2) // [[1, 2], [3, 4], [5]]
 */
export function chunkArray(array, size) {
	if (typeof size !== 'number') {
		size = Number(size);
	}
	const result = [];
	for (let i = 0; i < array.length; i += size) {
		let slice = array.slice(i, i + size);
		result.push(slice);
	}
	return result;
}

/**
 * @description Merge two arrays without duplicated values
 * @param {[any]} origin
 * @param {[any]} newArray
 * @returns {[any]}
 * @example
 * mergeWithoutDuplicates([1, 2, 3], [3, 4, 5]) // [1, 2, 3, 4, 5]
 */
export function mergeWithoutDuplicates(origin, newArray) {
	newArray.forEach((item) => {
		if (!origin.some(originItem => JSON.stringify(originItem) === JSON.stringify(item))) {
			origin.push(item);
		}
	});

	return origin;
}

/**
 * @description Transform an object into query parameters
 * @param {Object} obj
 * @returns {string}
 * @example
 * transformObjectToQueryParams({ name: 'John', age: 30 }) // 'name=John&age=30'
 */
export function transformObjectToQueryParams(obj) {
	return Object.keys(obj).map(key => key + '=' + obj[key]).join('&');
}

/**
 * @description Sort an array of objects by a specific key
 * @param {[object]} list
 * @param {string} key
 * @param {string} order
 * @returns {[*]}
 * @example
 * sortObjectListByKey([{ id: 2 }, { id: 1 }, { id: 3 }], 'id', 'asc') // [{ id: 1 }, { id: 2 }, { id: 3 }]
 */
export function sortObjectListByKey(list, key, order = 'asc') {
	return list.sort((a, b) => {
		if (order === 'asc') {
			return a[key] > b[key] ? 1 : -1;
		}
		return b[key] > a[key] ? 1 : -1;
	});
}

/**
 * @description returns a value matched
 * @param {*} value
 * @param {object} rules
 * @param {*|null} defaultValue
 * @returns {*|null}
 * @example
 * match(value, {
 *   test: 'test1',
 *   'test1': 'test2',
 *   1: 'test3',
 *   calc: () => 1 + 1,
 *   2: (value) => value + 1,
 * }, 'Incorrect value');
 *
 * value == 'test' // 'test1'
 * value == 'test1' // 'test2'
 * value == 'dfghjk' // 'Incorrect value'
 *
 * value == 0 // 'Incorrect value'
 * value == 1 // 'test3'
 *
 * value == 'calc' // 2
 * value == 2 // 3
 */
export function match(value, rules, defaultValue = null) {
	let returnValue = rules[value];
	if (!returnValue) {
		return defaultValue;
	}
	if (returnValue instanceof Function) {
		return returnValue(value);
	}
	return returnValue;
}

/**
 * @description Wrap a value into an array if it's not already an array
 * @param {*} value
 * @returns {[*]}
 * @example
 * arrayWrap('test') // ['test']
 * arrayWrap(['test']) // ['test']
 */
export function arrayWrap(value) {
	return Array.isArray(value) ? value : [value];
}

/**
 * @description Insert an object into an array at a specific index
 * @param {[*]} array
 * @param {number} index
 * @param {*} object
 * @returns {[*]}
 * @example
 * insertObjetOnIndex([1, 3, 4], 1, 2) // [1, 2, 3, 4]
 */
export function insertObjetOnIndex(array, index, object) {
	if (index > array.length - 1) {
		return [...array, object];
	}
	return [...array.slice(0, index), object, ...array.slice(index)];
}

/**
 * @description Make the first letter of a string uppercase
 * @param {string} str
 * @returns {string}
 * @example
 * ucfirst('test') // 'Test'
 */
export function ucfirst(str) {
	return str.charAt(0).toUpperCase() + str.slice(1);
}

/**
 *
 * @param {Blob | MediaSource} data
 * @param {string} filename
 * @description Show the data to the browser
 */
export function OpenData(data, filename){
	const link = document.createElement('a');
	link.style.display = 'none';
	document.body.append(link);

	link.href = URL.createObjectURL(data);
	link.download = filename;
	link.click();
	document.body.removeChild(link);
}

/**
 *
 * @param {*} data
 * @param {string} designationTypeDoc
 * @returns {Promise<StocksModel>}
 * @description Transform the data to a model recognized by the report service
 */
async function transformResponseIntoStocksModel(data, designationTypeDoc){
	let model = new StocksModel();

	let date = new Date(data.DO_Date);
	let formatter = new Intl.DateTimeFormat('fr-FR', {
		day: '2-digit',
		month: '2-digit',
		year: 'numeric'
	});

	let formattedDate = formatter.format(date);

	designationTypeDoc = designationTypeDoc.toLowerCase();
	let prefix = "aeiouy".includes(designationTypeDoc[0]) ? "d'" : "de ";
	model.Titre = `MOUVEMENT ${prefix.toUpperCase()}${designationTypeDoc.toUpperCase()} | ${data.DO_Piece}`;
	model.Ref = data.DO_Ref
	model.Dte = formattedDate
	model.NoMvt = data.DO_Piece;
	model.Adresse = "" // Sera rempli dans l'API
	model.NomSociete = ""; // Sera rempli dans l'API
	model.DataSet = [];
	for (const docLigne of data.DocLignes) {
		let article = docLigne.Article;
		let dataEntry = new StocksDataEntryModel();
		dataEntry.RefArt = article.AR_Ref;
		dataEntry.Qte = docLigne.DL_Qte;
		dataEntry.NoSerie = article.LS_NoSerie;
		dataEntry.Design = article.AR_Design;
		dataEntry.PdsBrut = docLigne.DL_PoidsBrut;
		dataEntry.PdsNet = docLigne.DL_PoidsNet;

		model.DataSet.push(dataEntry);
	}

	return model;
}

/**
 *
 * @param {*} data
 * @param {string} designationTypeDoc
 * @description Generate a stock pdf
 */
export async function printMouvement(data, designationTypeDoc){

	let model = await transformResponseIntoStocksModel(data, designationTypeDoc);
	let res = await generateStocksMouvement(model);
	let buf = new Uint8Array(res.data);
	OpenData(new Blob([buf], {type: 'application/pdf'}), `${data.DO_Piece}-${model.Dte}.pdf`);
}

/**
 * @typedef {Object} VentesModelResponse
 * @property {Uint8Array} data - Les données du PDF
 */

/**
 * Génère un nom de fichier pour le PDF
 * @param {Object} data - Les données du document
 * @returns {string} Le nom du fichier
 */
function generatePDFFilename(data) {
	if (!data?.DO_Piece) {
		return 'document.pdf';
	}

	const date = new Date(data.DO_Date);
	const formatter = new Intl.DateTimeFormat('fr-FR', {
		year: 'numeric',
		month: '2-digit',
		day: '2-digit'
	});

	const formattedDate = formatter.format(date).split('/').join('_');
	return `${data.DO_Piece}-${formattedDate}.pdf`;
}

/**
 * Génère un PDF de vente à partir des données fournies
 * @param {Object} data - Les données du document de vente
 * @returns {Promise<{blob: Uint8Array, filename: string}>}
 * @throws {Error} Si la transformation ou la génération échoue
 */
export async function getVentePDFBlob(data) {
	try {
		if (!data) {
			throw new Error('Les données du document sont requises');
		}

		const model = await transformResponseIntoVentesModel(data);
		if (!model) {
			throw new Error('Échec de la transformation du modèle');
		}

		const response = await generateDocVentes(model);
		if (!response?.data) {
			throw new Error('Échec de la génération du PDF');
		}

		return {
			blob: new Uint8Array(response.data),
			filename: generatePDFFilename(data)
		};
	} catch (error) {
		console.error('Erreur lors de la génération du PDF:', error);
		throw new Error(`Erreur lors de la génération du PDF: ${error.message}`);
	}
}

/**
 * Transforme les données de réponse en modèle de ventes
 * @param {Object} data - Les données à transformer
 * @returns {Promise<FactureModel|DevisModel>}
 * @throws {Error} Si la transformation échoue
 */
export async function transformResponseIntoVentesModel(data) {
	try {
		if (!data?.DO_Piece) {
			throw new Error('Numéro de pièce manquant dans les données');
		}

		const model = createModelInstance(data);
		await enrichModelWithClientData(model, data);
		await enrichModelWithDocumentData(model, data);
		
		return model;
	} catch (error) {
		console.error('Erreur lors de la transformation du modèle:', error);
		throw new Error(`Erreur lors de la transformation du modèle: ${error.message}`);
	}
}

/**
 * Crée une instance du modèle approprié en fonction du type de document
 * @private
 */
function createModelInstance(data) {
	if (data.DO_Piece.startsWith("FA")) {
		const model = new FactureModel();
		model.BIC = "AGRIFRPP895";
		model.IBAN = "IBAN FR7619506000112813093314004";
		return model;
	} else if (data.DO_Piece.startsWith("DE")) {
		const model = new DevisModel();
		model.CreditImpot = formatToCurrency(data.DO_TotalTTC / 2);
		return model;
	}
	throw new Error('Type de document non supporté');
}

/**
 * Enrichit le modèle avec les données du client
 * @private
 */
async function enrichModelWithClientData(model, data) {
	const client = (await getClientByReference(data.Client.CT_Num)).data;
	if (!client) {
		throw new Error('Client non trouvé');
	}

	const clientAddress = client.Adresse;
	const formattedAddress = formatClientAddress(clientAddress);

	model.AdresseClt = formattedAddress;
	model.Nom_Client = client.CT_Intitule;
	model.CL_Adresse_Liv = formatClientAddress(clientAddress, ', ');
}

/**
 * Enrichit le modèle avec les données du document
 * @private
 */
async function enrichModelWithDocumentData(model, data) {
	const formatter = new Intl.DateTimeFormat('fr-FR', {
		day: '2-digit',
		month: '2-digit',
		year: 'numeric'
	});

	model.Dte = formatDateForDisplay(data.DO_Date);
	model.NumDoc = data.DO_Piece;
	model.LogoPrincipal = "<Logo Principal>";
	model.LogoSecondaire = "<Logo Secondaire>";
	model.LogoSociete = "<Logo Societe>";
	model.Commentaire = "";
	model.TotalHT = data.DO_TotalHT;
	let tiersPayeur = (await getClientByReference(data.CT_NumPayeur)).data;
	let tiersPayeurAddress = formatClientAddress(tiersPayeur.Adresse);

	model.NomSociete = `${tiersPayeur.CT_Intitule}\n${tiersPayeurAddress}`;

	// Calcul des TVA
	data.DocLignes.sort((a, b) => a.DL_Ligne - b.DL_Ligne);
	const docLignes = data.DocLignes ?? [];
	
	// Calcul TVA 10%
	const lignesTVA10 = docLignes.filter(docLigne => docLigne.DL_Taxe1 === 10);
	model.TotalTVA10 = lignesTVA10.reduce((acc, docLigne) => 
		acc + (docLigne.DL_MontantHT * 0.10), 0);

	// Calcul TVA 20%
	const lignesTVA20 = docLignes.filter(docLigne => docLigne.DL_Taxe1 === 20);
	model.TotalTVA20 = lignesTVA20.reduce((acc, docLigne) => 
		acc + (docLigne.DL_MontantHT * 0.20), 0);

	model.TotalTTC = data.DO_TotalTTC;

	model.DataSet = docLignes.map(docLigne => {
		const dataEntry = new VentesDataEntryModel();
		const dateInter = formatDateSage(docLigne.InfoLibres?.find(info =>
			info.Intitule === "LS_DATE_PRESTATION"
		)?.Valeur);

		dataEntry.Numero = docLigne.DO_Piece;
		dataEntry.DteInter = dateInter ? formatter.format(new Date(dateInter)) : "";
		dataEntry.Prestation = docLigne.DL_Design;
		dataEntry.Qte = docLigne.DL_Qte;
		dataEntry.Unite = docLigne.Conditionnement?.EU_Enumere ?? "";
		dataEntry.PUHT = docLigne.DL_PrixUnitaire;
		dataEntry.TotalHT = docLigne.DL_MontantHT;

		return dataEntry;
	});
}

/**
 * Formate l'adresse du client
 * @private
 */
function formatClientAddress(address, separator='\n') {
	return [
		address.CT_Adresse,
		address.CT_Complement,
		`${address.CT_CodePostal} ${address.CT_Ville}`,
		address.CT_CodeRegion,
		address.CT_Pays
	].filter(Boolean).join(separator);
}

/**
 *
 * @param {*} data
 * @returns {Promise<void>}
 * @description Generate a ventes pdf
 */
export async function printVentes(data){
	let model = await transformResponseIntoVentesModel(data);

	let res = await generateDocVentes(model);
	let buf = new Uint8Array(res.data);
	OpenData(new Blob([buf], {type: 'application/pdf'}), `${data.DO_Piece}-${model.Dte}.pdf`);
}

export function formatDateSage(date) {
	if (!(typeof date === 'string')) {
		return new Date(date);
	}
	
	const months = ["jan", "feb", "mar", "apr", "may", "jun", "jul", "aug", "sep", "oct", "nov", "dec"];

	if (months.some(month => date.toLowerCase().includes(month.toLowerCase()))) {
		let [month, day, year, time] = date.split(' ');
		
		let monthIndex = months.findIndex(m =>
			month.toLowerCase().includes(m.toLowerCase())
		);
		
		if (monthIndex !== -1) {
			// Construire une date ISO valide
			return new Date(`${year}-${String(monthIndex + 1).padStart(2, '0')}-${day.padStart(2, '0')}T00:00:00`);
		}
	}

	// Si ce n'est pas un format français, essayer de parser directement
	let parsedDate = new Date(date);
	if (!isNaN(parsedDate.getTime())) {
		return parsedDate;
	}

	throw new Error('Invalid date format');
}

export function formatDateForInput(date){
	let year;
	let month;
	let day;
	if(!(date instanceof Date)) {
		date = formatDateSage(date);
	}
	if ((date instanceof Date) || !isNaN(date.getTime())) {
		year = date.getFullYear();
		month = String(date.getMonth() + 1).padStart(2, '0');
		day = String(date.getDate()).padStart(2, '0');
	}
	return `${year}-${month}-${day}`;
}

export function formatDateForDisplay(date) {
	if (!date) return '';
	
	try {
		const parsedDate = formatDateSage(date);
		return new Intl.DateTimeFormat('fr-FR', {
			day: '2-digit',
			month: '2-digit',
			year: 'numeric'
		}).format(parsedDate);
	} catch (error) {
		console.error('Error formatting date:', date, error);
		return '';
	}
}