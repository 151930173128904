<script>
	import { Router, Route, navigate } from 'svelte-routing';

	// Stores
	import { isConnected, showLayout, toggleLayout, sidebarOpen } from './store';

	// Components
	import Sidebar from './components/Sidebar/Sidebar.svelte';
	import Header from './components/Header.svelte';
	import ToastManager from './components/Toast/ToastManager.svelte';

	// Layout Pages
	import Article from './layouts/Article.svelte';
	import Auth from './layouts/Auth.svelte';
	import Client from './layouts/Client.svelte';
	import Vente from './layouts/Vente.svelte';
	import Administration from './layouts/Administration.svelte';
	import Urssaf from './layouts/Urssaf.svelte';

	// No layout Pages
	import Index from './views/Index.svelte';
	import NotFound from './views/404.svelte';
	import NotConnected from './views/401.svelte';

	import * as jose from 'jose';

	export let url = '';

	import { onMount, beforeUpdate } from 'svelte';

	onMount(async () => {
		document.documentElement.setAttribute('data-theme', 'assortirTheme');

		if (sessionStorage.getItem('token') == null) {
			navigate('/auth/login');
			return;
		}
		// Get the token from the sessionStorage
		let token = sessionStorage.getItem('token');

		// Convert the secret to Uint8Array
		const secret = new TextEncoder().encode(process.env.JWT_SECRET);

		// Verify if the user is authenticated
		try {
			await jose.jwtVerify(token, secret);
			isConnected.set(true);
			sidebarOpen.set(false);
			if(window.location.pathname === '/auth/login' || window.location.pathname === '/') {
				navigate('/accueil');
			}
		} catch (error) {
			console.log(error);
			sessionStorage.clear();
			isConnected.set(false);
		}
	});

	beforeUpdate(() => {
		// Toggle the layout
		toggleLayout(window.location.pathname);
	});
</script>

<Router {url}>
	{#if $isConnected}
		<!-- Route si l'utilisateur est connecté -->
		{#if $showLayout}
			<!-- Routes qui afficherons le header et la SideBar -->
			<div class="flex h-screen overflow-hidden">
				<Sidebar />
				<!-- Contenu -->
				<div class="h-screen relative flex flex-col flex-1 overflow-y-auto bg-gray-50 rounded-lg">
					<Header />
					<Route path="prestations/*" component={Article} />
					<Route path="clients/*" component={Client} />
					<Route path="ventes/*" component={Vente} />
					<Route path="urssaf/*" component={Urssaf} />
					<Route path="administration/*" component={Administration} />
					<Route path="accueil" component={Index} />
					<Route path="*" component={NotFound} />
				</div>
			</div>
		{:else}
			<!-- Route qui n'afficherons pas le Header et la SideBar -->
			<Route path="auth/*" component={Auth} />
		{/if}
	{:else}
		<!-- Route si l'utilisateur n'est pas connecté -->
		<Route path="auth/*" component={Auth} />
		<Route path="*" component={NotConnected} />
	{/if}
</Router>
<!-- Toast Manager pour toute l'application -->
<ToastManager />
