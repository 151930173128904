export const List = parseInt('1', 2);
export const Create = parseInt('10', 2);
export const Edit = parseInt('100', 2);
export const Delete = parseInt('1000', 2);
export const All = parseInt('11111111', 2);
export const allPermissions = [
	{ name: 'Lister', value: List },
	{ name: 'Creer', value: Create },
	{ name: 'Editer', value: Edit },
	{ name: 'Supprimer', value: Delete },
];
