

/**
 * @class Model
 * @description Model generated from schema
 * @property {string} Nom - 
 * @property {string} Prenom - 
 * @property {string} Civilite - 
 * @property {string} Email - 
 * @property {string} Telephone - 
 * @property {string} Portable - 
 * @property {string} Lien - 
 */
class ContactNewModel {
  /**
   * @constructor
   */
  constructor() {
    /** @type {string} */
    this.Nom = null;
    /** @type {string} */
    this.Prenom = null;
    /** @type {string} */
    this.Civilite = null;
    /** @type {string} */
    this.Email = null;
    /** @type {string} */
    this.Telephone = null;
    /** @type {string} */
    this.Portable = null;
    /** @type {string} */
    this.Lien = null;
  }
}

export default ContactNewModel;
