<script>
    import NewArticleForm from "../../components/Form/Article/NewArticleForm.svelte";
    import { onMount } from 'svelte';
    import { connectedUserHasAccess } from '../../API/Backend/api.js';
    import { Create } from '../../API/Backend/rights/permissions.js';

    onMount(() => {
        connectedUserHasAccess('article', Create);
    });
</script>

<div class="container mx-auto">
    <NewArticleForm />
</div>