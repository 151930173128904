<script>
	// Components
	import DataTable from '../../components/DataTable.svelte';

	import { getArticles } from '../../API/API_SAGE/ArticleQueries';

	// Store
	import { openDrawer, closeDrawer } from '../../store';

	import DuplicateArticleForm from '../../components/Form/Article/DuplicateArticleForm.svelte';
	import Drawer from '../../components/Drawer.svelte';
	import { getUniteVenteByReference } from '../../API/API_SAGE/UniteVenteQueries.js';
	import { onMount } from 'svelte';
	import { connectedUserHasAccess } from '../../API/Backend/api.js';
	import { List } from '../../API/Backend/rights/permissions.js';

	onMount(() => {
		connectedUserHasAccess('article', List);
	});

	async function handleArticle(
		strictSearchFields,
	 	strictSearchValues,
	 	excludeSearchFields,
	 	excludeSearchValues,
	 	searchFields,
		searchValues,
	 	OrderDirection,
	 	pageNumber,
	 	pageSize,
	 	OrderBy) {
		strictSearchFields = "AR_Sommeil," + strictSearchFields;
		strictSearchValues = "0," + strictSearchValues;
		let response = await getArticles(
			strictSearchFields,
			strictSearchValues,
			excludeSearchFields,
			excludeSearchValues,
			searchFields,
			searchValues,
			OrderDirection,
			pageNumber,
			pageSize,
			OrderBy);

		if (!Array.isArray(response.data)) {
			return response;
		}

		for (let item of response.data) {
			item.UniteVente = await getUniteVenteByReference(item.AR_UniteVen).then(res => res.data);
			let taxeFamille = item.FamilleCompta?.filter(res => res.FCP_Champ === 1 && res.FCP_Type === 0);
			if (taxeFamille) {
				if (item.AR_PrixTTC === 0) {
					item.AR_PrixTTC = item.AR_PrixVen * ((taxeFamille[0].TA_Taux + 100) /100);
				} else if (item.AR_PrixTTC === 1) {
					item.AR_PrixTTC = item.AR_PrixVen;
					item.AR_PrixVen = item.AR_PrixTTC / ((taxeFamille[0].TA_Taux + 100) /100);
				}
			}
			item.PUCoop = item.AR_PrixVen * 0.95;
		}
		return response;
	}

	let columns = [
		{ key: 'AR_Design', title: 'Désignation', canSearch: true },
		{ key: 'Famille.FA_Intitule', title: 'Famille', canSort: false, canSearch: false },
		{ key: 'UniteVente.U_Intitule', title: 'Unité de vente', canSort: false, canSearch: false },
		{ key: 'AR_PrixVen', title: 'Prix HT', type: 'currency', canSearch: true },
		{ key: 'PUCoop', title: 'PU Coop', type: 'currency', canSort: false, canSearch: false },
		{ key: 'AR_PrixTTC', title: 'Prix TTC', type: 'currency', canSort: false, canSearch: false }
	];

	function handleClick(message) {
		alert(message);
	}

	function handleRowClick(event) {
		const rowData = event.detail;
		openDrawer(rowData);
	}

	function handleDuplication(event) {
		const rowData = event.detail;
		openDrawer({ data: rowData, id: 'duplication' });
	}
</script>

<DataTable
	onClick={handleClick}
	getQuery={handleArticle}
	{columns}
	rowsPerPage={10}
	on:rowClick={handleRowClick}
	on:duplication={handleDuplication}
/>

<Drawer title="Duplication de l'article" id="duplication">
	<div slot="drawerContent" let:data>
		<DuplicateArticleForm AR_Ref="{data.AR_Ref}" on:closeDrawer={closeDrawer} />
	</div>
</Drawer>

<Drawer title="Détails de l'article">
	<div slot="drawerContent" let:data>
		<p>Référence: {data.AR_Ref}</p>
		<p>Désignation: {data.AR_Design}</p>
		<p>Famille: {data.Famille.FA_Intitule}</p>
		<p>Unité de vente: {data.UniteVente.U_Intitule}</p>
		<p>Prix Unitaire HT: {data.AR_PrixVen}</p>
	</div>
</Drawer>
